<template>
  <v-card
      class="toc mt-3"
  >
    <v-card-title
    >目录</v-card-title>
    <v-card-text>
      <ul>
        <li
            v-for="(heading, index) of headings"
            @click="$emit('click', index)"
            :class="{ active: activeIndex===index }"
            :style="{ paddingLeft:  (6 + (parseInt(heading.nodeName.slice(1)) - minHeadingLevel) * 12) + 'px'}"
        >{{ heading.innerText }}</li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Toc",
  props: {
    headings: {
      type: Array,
      default: [],
    },
    activeIndex: {
      type: Number,
      default: 0,
    }
  },
  computed: {
    minHeadingLevel() {
      let minLevel = 6
      this.headings.forEach(heading => {
        let headingLevel = parseInt(heading.nodeName.slice(1))
        if (headingLevel < minLevel) {
          minLevel = headingLevel
        }
      })
      return minLevel
    }
  }
}
</script>

<style scoped lang="scss">
  .toc {
    position: sticky;
    top: 12px;
    min-height: calc(100vh - 24px);
    ul {
      padding-left: 0;
    }
    li {
      list-style: none;
      margin-bottom: 4px;
      line-height: 2;
      font-size: 14px;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .active {
      color: #0366d6;
      background-color: #f6f8fa;
    }
  }
</style>