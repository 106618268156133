import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import zhHans from 'vuetify/lib/locale/zh-Hans';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                anchor: 'rgba(0, 0, 0, 0.87)',
            },
            dark: {
                anchor: 'rgba(155, 155, 155, 0.87)',
            }
        }
    },
    lang: { zhHans },
    current: 'zhHans',
});
