var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"v-bar--underline",attrs:{"height":this.$vuetify.breakpoint.mdAndUp ? 64 : '',"flat":"","absolute":"","app":""}},[_c('v-app-bar-nav-icon',{class:{ 'd-none': this.$vuetify.breakpoint.mdAndUp },on:{"click":function($event){return _vm.$emit('toggleDrawer')}}}),_c('div',{staticClass:"d-flex flex-grow-1 align-center",class:{ 'justify-center': !this.$vuetify.breakpoint.mdAndUp, 'bar-content': !this.$vuetify.breakpoint.mdAndUp }},[_c('router-link',{staticClass:"d-inline-block",attrs:{"to":{ name:'Home' }}},[_c('v-img',{attrs:{"src":require('@/assets/logo.svg'),"max-width":"34","transition":"scale-transition"}})],1),_c('div',{staticClass:"d-md-flex flex-grow-1 hidden-md-and-down align-center ml-6"},[_vm._l((_vm.links),function(link,index){return _c('v-btn',{key:index,attrs:{"to":{ name: link.slug},"exact":"","tile":"","plain":"","width":"90","x-large":""}},[_vm._v(" "+_vm._s(link.name)+" ")])}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{ name: 'CreateArticle' }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"light-green"}},[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("写新文章")])]),_c('v-menu',{attrs:{"offset-y":"","left":"","open-on-hover":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-translate ")]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}])},[_c('v-list',{attrs:{"nav":"","dense":"","expand":""}},[_c('v-subheader',{staticClass:"text--primary font-weight-black text-uppercase"},[_vm._v(" 切换语言 ")]),_c('v-list-item-group',{attrs:{"value":_vm.$vuetify.lang.current}},_vm._l((_vm.locales),function(title,locale){return _c('v-list-item',{key:locale,attrs:{"value":locale,"link":""},on:{"click":function($event){return _vm.changeLocale(locale)}}},[_c('v-list-item-title',[_vm._v(_vm._s(title))])],1)}),1)],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.toggleDark}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-theme-light-dark")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.theme.dark ? "深色" : "浅色"))])]),(_vm.user)?_c('v-menu',{attrs:{"offset-y":"","left":"","open-on-hover":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"value":_vm.user.notification_count > 0,"dot":"","color":"red","overlap":""}},[_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.user.name)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)],1)]}}],null,false,765080317)},[_c('v-list',{attrs:{"nav":""}},[_c('v-list-item',{attrs:{"to":{ name: 'User', params: { userId: _vm.user.id } }}},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"alt":_vm.user.name + ' avatar',"src":_vm.user.avatar}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.user.email)+" ")]),_c('v-list-item-subtitle',[_vm._v("访问个人主页")])],1)],1),_c('v-divider'),_c('v-list-item',{staticClass:"align-center",attrs:{"link":""},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'notifications' })}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-alarm-light-outline")]),_c('v-badge',{attrs:{"value":_vm.user.notification_count > 0,"content":_vm.user.notification_count,"color":"red"}},[_vm._v(" 我的消息 ")])],1)],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'UserEdit' }}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-cog")]),_vm._v(" 设置 ")],1)],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.signOut(_vm.$route)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-logout")]),_vm._v(" 退出 ")],1)],1)],1)],1)],1):_c('v-btn',{attrs:{"to":{ name: 'Signin' },"tile":"","plain":"","width":"90","x-large":""}},[_vm._v(" 登录 ")])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }