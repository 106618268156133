<template>
  <v-container
  >
    <v-row>
      <v-col
          cols="12"
          md="1"
          v-if="!$vuetify.breakpoint.smAndDown"
      >
        <div
            class="edit-container d-flex flex-column justify-center align-start"
        >
          <div>
            <v-btn
                :to="{ name: 'EditArticle', params: { articleId: article.id } }"
                icon
                color="green"
                v-if="article.user_id === user.id"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>

          <div>
            <v-btn
                icon
                color="red"
                v-if="article.user_id === user.id"
                @click="deleteArticle"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>

          <div class="text-center">
            <v-btn
                icon
                href="#"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <span class="mr-2">{{ article.view_count }}</span>
          </div>

          <div class="text-center">
            <v-btn
                icon
                href="#reply"
            >
              <v-icon>mdi-message-reply-text</v-icon>
            </v-btn>
            <span class="mr-2">{{ article.reply_count }}</span>
          </div>

          <ArticleVotable
              :votable.sync="article"
              :iconIsSmall="false"
              class="flex-column"
          >
          </ArticleVotable>
        </div>



      </v-col>

      <v-col
          cols="12"
          md="8"
          :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
      >
        <v-skeleton-loader
            v-if="loadingArticle"
            type="list-item-avatar-two-line, image, article"
            elevation="2"
        ></v-skeleton-loader>
        <v-card
            class="article"
            :flat="$vuetify.breakpoint.smAndDown"
        >
          <v-card-actions
          >
            <v-list-item
                class="grow"
            >
              <v-list-item-avatar
                  @click="$router.push({ name: 'User', params: { userId: article.user.id } })"
                  style="cursor: pointer"
              >
                <v-img
                    :alt="article.user.name + ' avatar'"
                    :src="article.user.avatar"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                    @click="$router.push({ name: 'User', params: { userId: article.user.id } })"
                    style="cursor: pointer"
                >
                  {{ article.user.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ article.created_at }}

                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action
              >
                <UserVotable
                    :likeCountIsShow="true"
                    :iconIsSmall="false"
                    :votable.sync="article.user"
                ></UserVotable>
              </v-list-item-action>
            </v-list-item>
          </v-card-actions>

          <v-img
              v-if="article.hero"
              :src="article.hero"
          ></v-img>

          <v-card-title
              class="text-center justify-center text-h3 mt-4"
          >
            {{ article.title }}
          </v-card-title>

          <v-card-text>
            <Viewer
                :value="article.body"
                :plugins="plugins"
            ></Viewer>
          </v-card-text>
        </v-card>

        <Replies
            :articleId="articleId"
            :articleUser="article.user"
            :count="article.reply_count"
        >
        </Replies>
      </v-col>

      <v-col
          cols="12"
          md="3"
          :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
      >
        <v-card
            class="d-flex flex-column align-center"
        >
          <v-subheader>
            <h3>作者</h3>
          </v-subheader>
          <v-card-title
          >
            <v-avatar
                size="100"
            >
              <img :src="article.user.avatar" :alt="article.user.name">
            </v-avatar>
          </v-card-title>

          <v-card-subtitle
              class="mt-2"
          >
            <h2>
              {{ article.user.name }}
            </h2>
          </v-card-subtitle>

          <v-card-text
              class="text-center"
          >
            <p>加入于&nbsp;&nbsp;{{ article.user.created_at}}</p>
            <p>最后活跃于&nbsp;&nbsp;{{ article.user.updated_at}}</p>
          </v-card-text>
        </v-card>

        <Toc
            @click="handleClick"
            :headings="headings"
            :activeIndex="activeIndex"
            v-if="!$vuetify.breakpoint.smAndDown"
        >
        </Toc>
      </v-col>
    </v-row>

    <v-app-bar
        v-if="$vuetify.breakpoint.smAndDown"
        fixed
        bottom
        dense
        height="60"
    >
      <v-row
          justify="space-between"
      >
        <v-btn
            :to="{ name: 'EditArticle', params: { articleId: article.id } }"
            fab
            icon
            small
            color="green"
            v-if="article.user_id === user.id"
            class="ma-2"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
            fab
            icon
            small
            color="red"
            v-if="article.user_id === user.id"
            class="ma-2"
            @click="deleteArticle"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <div class="d-flex align-center">
          <v-btn
              icon
              href="#reply"
          >
            <v-icon small>mdi-message-reply-text</v-icon>
          </v-btn>
          <span
              class="mr-2"
          >{{ article.reply_count }}</span>
        </div>

        <ArticleVotable
            :votable.sync="article"
        >
        </ArticleVotable>
      </v-row>

    </v-app-bar>
  </v-container>

</template>

<script>
import { Viewer } from '@bytemd/vue'
import { plugins, locale} from "@/plugins/bytemd"
import Toc from "@/components/Toc"
import { mapState } from "vuex";
import { Message } from 'element-ui'
import Replies from '@/components/Replies'
import ArticleVotable from "@/components/ArticleVotable"
import UserVotable from "@/components/UserVotable";

export default {
  name: 'Content',
  components: {
    Viewer,
    Toc,
    Replies,
    ArticleVotable,
    UserVotable,
  },
  data() {
    return {
      article: { user: { name: ''} },
      replies: [],
      headings: [],
      activeIndex: 0,
      replyUrl: '',
      loadingArticle: true,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
    articleId() {
      return parseInt(this.$route.params.articleId)
    }
  },
  methods: {
    async deleteArticle() {
      const result = await this.$http.delete(`articles/${this.article.id}`)
      Message({
        message: '删除文章成功！',
        type: 'success',
        center: true,
        onClose: message => {
          this.$router.push({name: 'Home'})
        }
      })
    },
    async getArticle(articleId) {
      const article = await this.$http.get(`articles/${articleId}?include=user.votingLikeUsers,votingLikeUsers, votingDislikeUsers`)
      this.article = Object.assign({}, article.data)
      document.title = `${article.data.title}`
      this.loadingArticle = false
      this.$nextTick(function () {
        let bodyHtml = document.querySelector('.markdown-body')
        let headings = Array.prototype.slice.call(bodyHtml.querySelectorAll("h1, h2, h3, h4, h5, h6"))
        this.headings.push(...headings)

        this.headings.forEach(heading => {
          this.intersectionObserver.observe(heading)
        })
      })
    },
    handleClick(index) {
      this.intersectionObserver.disconnect()
      this.activeIndex = index
      this.$vuetify.goTo(this.headings[index]).then(v => {
        this.headings.forEach(heading => {
          this.intersectionObserver.observe(heading)
        })
      })
    },
  },
  created() {
    this.plugins = plugins
    this.locale = locale
    this.getArticle(this.articleId)
    this.intersectionObserver = new IntersectionObserver(
        entries => {
          entries.some(entry => {
            if (entry.isIntersecting) {
              this.activeIndex = this.headings.indexOf(entry.target)
            }
            return entry.isIntersecting
          })
        }, { rootMargin: "0% 0% -80% 0%" })
  },
  beforeDestroy() {
    this.intersectionObserver.disconnect()
  }
}
</script>

<style lang="scss">
@import "@/styles/markdown.scss";
@import "~katex/dist/katex.min.css";
.article{
  .title {
    margin: .5em 0;
    line-height: 1.5;
    font-weight: 700;
  }
}
.edit-container {
  position: sticky;
  top: 0;
  height: 100vh;
}
</style>