<template>
  <v-container>
    <v-row
        justify="center"
    >
      <v-col
          cols="12"
          md="6"
      >
        <v-card>
          <v-card-title>
            我的消息
            <v-spacer></v-spacer>
            <v-btn
                v-if="user.notification_count"
                @click="readAll"
            >
              已阅所有消息
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-list>

              <template v-for="(notification, index) in notifications">
                <v-divider
                    inset
                    v-if="index !== 0"
                ></v-divider>

                <v-list-item
                    two-line
                >
                  <v-list-item-avatar
                      class="align-self-start"
                  >
                    <v-img contain :src="notification.data.user_avatar"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                    >
                      {{ notification.data.user_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                        class="mt-2"
                    >
                      回复了我的文章
                    </v-list-item-subtitle>

                    <v-card
                        :to="{ path: `/articles/${notification.data.article_id}#reply` }"
                    >
                      <v-card-subtitle
                          class="blue--text text--darken-2"
                      >
                        @原文：{{ notification.data.article_title }}
                      </v-card-subtitle>
                    </v-card>

                    <v-list-item-subtitle
                        class="text-h6 mt-2"
                        v-text="notification.data.reply_content"
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text >
                      <span v-if="!notification.read_at"  style="color: red; font-size: 60px;">.</span>
                      {{ notification.created_at }}
                    </v-list-item-action-text>
                    <v-icon
                        color="green"
                        @click="read(notification, index)"
                    >
                      {{ notification.read_at ? '' : 'mdi-check' }}
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
            <v-skeleton-loader
                v-intersect="onscroll"
                v-if="url"
                cols="12"
                type="image,list-item-avatar,card-heading,actions"
                elevation="2"
            ></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Notification",
  data() {
    return {
      notifications: [],
      url: 'notifications'
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    })
  },
  methods: {
    ...mapActions([
      'getUser',
    ]),
    async getNotifications() {
      let notifications = await this.$http.get(this.url)
      this.url = notifications.data.links.next
      return notifications.data.data
    },
    async onscroll(entries, observer) {
      if(entries[0].isIntersecting) {
        if (this.url !== null) {
          const notifications = await this.getNotifications(this.url)
          // console.log(notifications)
          this.notifications.push(...notifications)
        }
      }
    },
    async readAll() {
      let result = await this.$http.patch('user/read/notifications')
      // console.log(result)
      this.notifications = result.data.data
      this.getUser()
    },
    async read(notification, index) {
      let result = await this.$http.patch(`user/read/notifications/${notification.id}`)
      // console.log(result)
      this.notifications.splice(index, 1, result.data)
      this.getUser()
    }
  },
  created() {
    this.getNotifications()
    // this.notifications = notifications.data
  }
}
</script>

<style scoped>

</style>