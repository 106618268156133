import axios from 'axios'
import router from "@/router"
import store from "@/store"
import { Message } from 'element-ui'

const config = {
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 8000,
    headers: {
        Accept: 'application/json',
    },
    // validateStatus: function (status) {
    //     return status >= 200 && status < 500; // default
    // },
}

Object.assign(axios.defaults, config)

axios.interceptors.request.use(
    config => {
        //在异步中取得store，因为在此之前store为undefined
        config.headers['Authorization'] = `Bearer ${ store.state.user.token }`
        return config
    },
    error => {
        return Promise.reject(error)
    })

axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    router.push({ name: 'NotFound', })
                    console.log(error.response)
                    break;
                case 401:
                    store.commit('resetState')
                    Message({
                        message: '需要登录，请先登录!',
                        center: true,
                        type: 'error',
                        onClose: message => {
                            router.push({name: 'Signin', params: {redirect: router.currentRoute}})
                        }
                    })
                    break;
                case 500:
                    console.log(error.response)
                    Message({
                        message: '服务器错误！',
                        center: true,
                        type: 'error',
                    })
                    break;
            }
        } else if (error.request) {
            if (error.code && error.code === 'ECONNABORTED') {
                Message({
                    message: '网络连接超时！',
                    center: true,
                    type: 'error',
                    showClose: true,
                })
            } else if ( error.message === 'Network Error' ){
                Message({
                    message: '网络错误！',
                    center: true,
                    type: 'error',
                })
            }
        } else {
            Message({
                message: 'axios 请求出错！',
                center: true,
                type: 'error',
            })
        }
        return Promise.reject(error)
    })

export default axios