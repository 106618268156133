<template>
  <Votable
      votableName="user"
      :likeCountIsShow="likeCountIsShow"
      :dislikeIsShow="false"
      :dislikeCountIsShow="false"
      :iconIsSmall="false"
      likeIcon="mdi-heart"
      likeColor="red"
      dislikeIcon="mdi-close"
      v-bind="$attrs"
      v-on="$listeners"
  ></Votable>
</template>

<script>
import Votable from "@/components/Votable"
export default {
  name: "UserVotable",
  components: {
    Votable,
  },
  props: {
    likeCountIsShow: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style scoped>

</style>