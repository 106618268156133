<template>
  <v-card>
    <v-list>
      <template v-for="(user,index) in likeUsers">
        <v-divider
            v-if="index !== 0"
        ></v-divider>

        <v-list-item
        >
          <v-list-item-avatar
              class="align-self-start"
              style="cursor: pointer;"
              @click="$router.push({ name: 'User', params: { userId: user.id } })"
          >
            <v-img contain :src="user.avatar"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
                style="cursor: pointer;"
                @click="$router.push({ name: 'User', params: { userId: user.id } })"
                v-text="user.name"
            ></v-list-item-title>
            <v-list-item-subtitle>
              {{ user.created_at }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <UserVotable
                v-bind:votable.sync="likeUsers[index]"
            ></UserVotable>
          </v-list-item-action>
        </v-list-item>
      </template>

      <v-row
          v-intersect="onscroll"
          v-if="url"
      >
        <v-col
            v-for="i in 4"
            cols="12"
        >
          <v-skeleton-loader
              type="list-item-avatar-two-line"
              elevation="2"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

    </v-list>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import UserVotable from "@/components/UserVotable";

export default {
  name: "VotedLikeUsersList",
  components: {
    UserVotable
  },
  data: () => ({
    likeUsers: [
    ],
    url: '',
  }),
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
  },
  methods: {
    async getLikeUsers(url) {
      // console.log(url)
      const result = await this.$http.get(url)
      this.url = result.data.links.next
      // console.log(result.data)
      return result.data.data
    },
    async onscroll(entries, observer) {
      if(entries[0].isIntersecting) {
        if (this.url !== null) {
          const likeUsers = await this.getLikeUsers(this.url)
          this.likeUsers.push(...likeUsers)
        }
      }
    },
  },
  created() {
    this.url = 'users/' + this.$route.params.userId + '/voted-like-users?include=votingLikeUsers'
  }
}
</script>

<style scoped>

</style>