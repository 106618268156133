<template>
  <v-footer
      padless
  >
    <v-row
        justify="center"
        no-gutters
    >
      <v-card
          flat
          tile
          class="text-center flex-grow-1"
      >
        <v-card-text>
          <h1 class="pb-2">关注我们</h1>
          <a v-bind:href="social.url" v-for="social in socials" class="text-decoration-none">
            <v-btn class="mx-4" icon>
              <v-icon>{{ social.icon }}</v-icon>
            </v-btn>
          </a>
        </v-card-text>

        <v-card-text class="pt-0 text-left text-md-center">
          <v-expansion-panels accordion v-model="panel" multiple>
            <v-row>
              <v-col
                  md="3"
                  cols="12"
              >
                <v-expansion-panel  class="elevation-0">
                  <h2 class="d-none d-md-block py-2">推荐链接</h2>
                  <v-expansion-panel-header class="d-md-none">推荐链接</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <li v-for="link in links" class="my-1">
                      <router-link :to="{ name: link.slug}" class="text-decoration-none">{{ link.name}} </router-link>
                    </li>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-col>
              <v-col
                  md="3"
                  v-for="(item,i) in items"
                  :key="i"
                  cols="12"
              >
                <v-expansion-panel  class="elevation-0">
                  <h2 class="d-none d-md-block py-2">{{ item.name }}</h2>
                  <v-expansion-panel-header class="d-md-none">{{ item.name }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <li v-for="tag in item.tags" class="my-1">
                      <a v-bind:href="tag.url" class="text-decoration-none">{{tag.urlname}} </a>
                    </li>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-col>
            </v-row>

          </v-expansion-panels>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="mt-2">
          &copy;{{ new Date().getFullYear() }} {{ title }}, Inc.
        </v-card-text>
      </v-card>
    </v-row>

  </v-footer>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "TheFooter",
  data: () => ({
    title: process.env.VUE_APP_TITLE,
    socials: [
      { name:"wechat", icon:"mdi-wechat", url:"/contact"},
      {name:"weibo", icon:"mdi-sina-weibo", url:"https://weibo.com/u/7744251587"},
      {name:"facebook", icon:"mdi-facebook", url:"https://www.facebook.com/Yanyinge-104860895687532"},

    ],
    items: [
      { name: "关于我们",
        tags: [
          { urlname: "我们的故事", url: "#" },
          { urlname: "互动规则", url: "/rules" },
        ]
      },
      { name: "联系我们",
        tags: [
          { urlname: "微信", url: "/contact" },
          { urlname: "电子邮箱", url: "mailto:yushizhi@live.com" },
          { urlname: "手机号", url: "tel:18190955997" },
        ]
      },
      { name: "账户问题",
        tags: [
          { urlname: "忘记密码", url: "/rest-password" },
          { urlname: "登录", url: "/signin" },
          { urlname: "注册", url: "/" },
        ]
      }
    ]
  }),
  computed: {
    panel: {
      get: function () {
        return this.$vuetify.breakpoint.mdAndUp ? [...Array(4).keys()].map((k, i) => i) : []
      },
      set: function() {

      },
    },
    ...mapState({
      links: state => state.categories,
    })
  },
}
</script>

<style scoped>
@media (min-width:960px) {
  .v-expansion-panel:before {
    box-shadow: none !important;
  }
  .v-expansion-panel:not(:first-child)::after {
    border-top: none;
  }
}
.resize {
  text-align: center;
}
.resize {
  font-size: 1.25rem;
}
</style>