import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { Message } from "element-ui"

Vue.use(VueRouter)

const routes = [
  {
    path: '/articles/create',
    name: 'CreateArticle',
    component: () => import('../views/articles/Create.vue'),
    meta: {
      requiresAuth: true,
      title: '创建文章',
    },
  },
  {
    path: '/articles/:articleId/edit',
    name: 'EditArticle',
    component: () => import('../views/articles/Create.vue'),
    meta: {
      requiresAuth: true,
      title: '编辑文章',
    },
  },
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('../views/Article.vue'),
        meta: {
          title: '',
        },
      },
      {
        path: 'notifications',
        name: 'notifications',
        component: () => import('../views/Notification.vue'),
        meta: {
          requiresAuth: true,
          title: '我的消息',
        },
      },
      {
        path: 'contact',
        name: 'Contact',
        component: () => import('../views/Contact.vue'),
        meta: {
          title: '联系我们',
        },
      },
      {
        path: 'rules',
        name: 'Rules',
        component: () => import('../views/Rules.vue'),
        meta: {
          title: '互动规则',
        },
      },
      {
        path: 'code',
        name: 'Code',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Article.vue'),
        meta: {
          title: '编程',
        },
      },
      {
        path: 'life',
        name: 'Life',
        component: () => import('../views/Article.vue'),
        meta: {
          title: '生活',
        },
      },
      {
        path: 'essay',
        name: 'Essay',
        component: () => import('../views/Article.vue'),
        meta: {
          title: '写作',
        },
      },
      {
        path: 'read',
        name: 'Read',
        component: () => import('../views/Article.vue'),
        meta: {
          title: '读书',
        },
      },
      {
        path: 'articles/:articleId',
        name: 'ArticleContent',
        component: () => import('../views/articles/Content.vue'),
        meta: {
          title: '文章',
        },
      },
      {
        path: 'users/:userId',
        name: 'User',
        component: () => import('../views/users/Index.vue'),
        meta: {
          title: '个人主页',
        },
      },
      {
        path: 'user/edit',
        name: 'UserEdit',
        component: () => import('../views/users/Edit.vue'),
        meta: {
          requiresAuth: true,
          title: '个人设置',
        },
      }
    ],
  },
  {
    path: '/signin',
    name: 'Signin',
    component: () => import('../views/Signin.vue'),
    meta: {
      title: '登录',
    },
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/Signup.vue'),
    meta: {
      title: '注册',
    },
  },
  {
    path: '/rest-password',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue'),
    meta: {
      title: '密码重置',
    },
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
    meta: {
      title: '404',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    } else {
      return { top: 0 }
    }
  }
})

router.beforeEach( (to, from, next) => {
  let documentTitle = process.env.VUE_APP_TITLE
  if (to.meta.title) {
    documentTitle += `-${to.meta.title}`
  }
  document.title = documentTitle

  const token = router.app.$options.store.state.user.token
  if (token && to.name && to.name.indexOf('Sign') !== -1) {
    next({ name: 'Home' })
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      Message({
        message: '请先登录！',
        type: 'warning',
        center: true,
        onClose: message => {
          next({name: 'Signin'})
        }
      })
    }  else {
      next()
    }
  } else {
    next()
  }
})

export default router
