<template>
  <div class="text-center">
    <v-btn
        dark
        color="red darken-2"
        @click="snackbar = true"
    >
      Open Snackbar
    </v-btn>

    <v-snackbar
        v-model="snackbar"
        app
        content-class="custom"
        centered
        top
        timeout="0"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            icon
            v-bind="attrs"
            @click="snackbar = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '这里什么也没有'
    }
  },
  data: () => ({
    snackbar: false,
  }),
}
</script>

<style>
  .custom {
    display: flex;
    justify-content: center;
  }
</style>