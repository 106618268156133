<template>
  <v-app-bar
      app
      class="transparent"
      :height="this.$vuetify.breakpoint.mdAndUp ? 64 : ''"
      flat
      absolute
  >
    <router-link
        :to="{ name:'Home' }"
        class="d-inline-block px-4"
    >
      <v-img
          :src="require('@/assets/logo.svg')"
          max-width="34"
          transition="scale-transition"
      >
      </v-img>
    </router-link>

    <v-spacer></v-spacer>

    <v-btn
        v-if="!user"
        :to="{ name: 'Signin' }"
        tile
        plain
        width="90"
        x-large
    >
      登录
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TheSimpleHeader",
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
}
</script>

<style scoped>
  
</style>