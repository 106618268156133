<template>
  <v-app>
    <v-app-bar
        app
        class="transparent"
        height="95"
        flat
        absolute
    >
      <router-link
          :to="{ name:'Home' }"
          class="d-inline-block px-4"
      >
        <v-img
            :src="require('@/assets/logo.svg')"
            max-width="34"
            :aspect-ratio="1"
            transition="scale-transition"
        >
        </v-img>
      </router-link>

      <v-spacer></v-spacer>

      <v-btn
          v-if="!user"
          :to="{ name: 'Signin' }"
          plain
          width="90"
          x-large
          color="white"
      >
        登  录
      </v-btn>
    </v-app-bar>
    <v-img
        :src="bgiUrl"
        style="background-color: black; height: calc(100vh - 95px);"
    >
      <Verify
          @success="success"
          mode="pop"
          :captchaType="captchaType"
          :imgSize="{ width: '330px', height: '155px' }"
          ref="verify"
      ></Verify>
      <v-container
          class="fill-height"
      >
        <v-row
            justify="center"
            no-gutters
        >
          <v-col
          >
            <v-card
                :flat="!$vuetify.breakpoint.smAndUp"
                max-width="474"
                class="mx-auto px-sm-13 py-sm-15 sign-card"
                min-height="400"
            >
              <v-card-title>
                <h2 class="mb-7 white--text">修改密码</h2>
              </v-card-title>
              <v-card-text>
                <v-form
                    ref="emailForm"
                    width="380px"
                    v-model="validateEmail"
                >
                  <v-text-field
                      :rules="rules.email"
                      v-model="email"
                      label="电子邮箱"
                      outlined
                      background-color="rgb(51, 51, 51)"
                      color="rgb(140, 140, 140)"
                      class="custom-color"
                      @input="asyncErrorMessage.email = ''"
                      :error-messages="asyncErrorMessage.email"
                      :append-icon="sendBtnText"
                      @click:append="sendEmailCode()"
                  >
                  </v-text-field>
                </v-form>

                <v-form
                    v-if="emailSend"
                    ref="form"
                    width="380px"
                    v-model="validate"
                >
                  <v-text-field
                      outlined
                      background-color="rgb(51, 51, 51)"
                      color="rgb(140, 140, 140)"
                      class="custom-color"
                      label="邮箱验证码"
                      v-model="form.email_code"
                      :rules="rules.email_code"
                      :error-messages="asyncErrorMessage.email_code"
                      @focus="asyncErrorMessage.email_code = ''"
                  >
                  </v-text-field>

                  <v-text-field
                      background-color="rgb(51, 51, 51)"
                      color="rgb(140, 140, 140)"
                      class="custom-color"
                      v-model="form.password"
                      :rules="rules.password"
                      label="密码"
                      outlined
                      @focus="asyncErrorMessage.username = ''"
                      :error-messages="asyncErrorMessage.password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                  ></v-text-field>

                  <v-btn
                      block
                      x-large
                      color="#E50914"
                      class="white--text my-3"
                      depressed
                      @click="resetPassword"
                      :loading="loading"
                  >
                    确认
                  </v-btn>
                </v-form>
              </v-card-text>

            </v-card>
          </v-col>

        </v-row>
      </v-container>

    </v-img>
  </v-app>
</template>

<script>
import {Message} from "element-ui";
import TheSimpleHeader from "@/components/TheSimpleHeader";
import { mapState} from "vuex";
import Verify from "@/components/verifition/Verify"

export default {
  name: "ResetPassword",
  components: {
    TheSimpleHeader,
    Verify,
  },
  computed: {
    bgiUrl() {
      return this.$vuetify.breakpoint.smAndUp ?
          require('@/assets/sign_in_background.jpg')
          : ''
    },
    ...mapState({
      user: state => state.user.user
    })
  },
  data() {
    return {
      email: '',
      form: {
        password: '',
        email_code: '',
        code_key: '',
      },
      sendBtnText: 'mdi-send',
      totalTime: 60,
      validateEmail: false,
      validate: false,
      showPassword: false,
      loading: false,
      emailSend: false,
      asyncErrorMessage: {
        email: '',
        password: '',
        email_code: '',
      },
      rules: {
        email: [
          v => !!v || '请输入电子邮箱',
          v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(v).toLowerCase()) || '请输入有效的电子邮箱',
        ],
        password: [
          v => !!v || '请输入密码',
        ],
        email_code: [
          v => !!v || '请输入邮箱验证码',
        ],
      },
      captchaType: ['clickWord', 'blockPuzzle'][Math.floor(Math.random()*2)],
    }
  },
  methods: {
    async sendEmailCode(captchaVerification) {
      if (this.sendBtnText === 'mdi-send' && this.$refs.emailForm.validate()) {
        this.sendBtnText === 'mdi-send'
        try {
          let data = { email: this.email }
          if (captchaVerification) {
            data = Object.assign({}, data, captchaVerification)
          }
          const res = await this.$http.post('email-verification-code', data)
          Message({
            message: '验证码已发送！',
            type: 'success',
            center: true,
          })
          this.emailSend = true
          this.sendBtnText = String(this.totalTime)
          const timer = window.setInterval(() => {
            this.totalTime--
            this.sendBtnText = String(this.totalTime)
            if (this.totalTime < 0) {
              window.clearInterval(timer)
              this.sendBtnText = 'mdi-send'
              this.totalTime = 60
            }
          }, 1000)
          this.form.code_key = res.data.key
        } catch (e) {
          if (e.response.status === 422) {
            const errors = e.response.data.errors
            Object.assign(this.asyncErrorMessage, errors)
          } else if (e.response.status === 429) {
            this.useVerify()
          } else {
            console.log(e.response)
          }
        }
      }
    },
    success(captchaVerification){
      this.sendEmailCode(captchaVerification)
    },
    useVerify(){
      this.$refs.verify.show()
    },
    async resetPassword() {
      if (this.$refs.form.validate()) {
        this.loading = true
        try {
          await this.$http.post('reset-password', this.form)
          Message({
            message: '密码修改成功！请登录。',
            type: 'success',
            center: true,
            onClose: message => {
              this.$router.push({ name: 'Signin' })
            }
          })
        } catch (e) {
          if (e.response) {
            if (e.response.status === 422) {
              const errors = e.response.data.errors
              if (errors.code_key) {
                errors.email_code ? errors.email_code.push('验证码缺少key') : errors.email_code = '验证码缺少key'
              }
              Object.assign(this.asyncErrorMessage, errors)
            }
            if (e.response.status === 403) {
              this.asyncErrorMessage.email_code = e.response.data.message
            }
          }

        }
        this.loading = false
      }
    }
  },
}
</script>

<style>
  .sign-card {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  .custom-color .v-label {
    color: rgb(238, 236, 236) !important;
  }
  .custom-color .v-icon {
    color: rgb(238, 236, 236) !important;
  }
  .custom-color input {
    color: white !important;
  }
  .forget-password {
    font-size: 16px;
  }
  .white-text-checkbox .v-label {
    color: white !important;
  }
</style>