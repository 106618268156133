<template>
  <div
      class="d-flex align-center"
  >
    <div class="text-center">
      <v-btn
          icon
          @click="like"
          :color="votable.authUserIsVotingLikeUser ? likeColor : ''"
          :loading="likeLoading"
          :disabled="likeLoading"
      >
        <v-icon :small="iconIsSmall">{{ likeIcon }}</v-icon>
      </v-btn>
      <span
          class="mr-2"
          v-if="likeCountIsShow"
      >{{ votable.votingLikeUsersCount }}</span>
    </div>

    <div class="text-center">
      <v-btn
          v-if="dislikeIsShow"
          icon
          @click="dislike"
          :color="votable.authUserIsVotingDislikeUser ? dislikeColor : ''"
          :loading="dislikeLoading"
          :disabled="dislikeLoading"
      >
        <v-icon :small="iconIsSmall">{{ dislikeIcon }}</v-icon>
      </v-btn>
      <span
          class="mr-2"
          v-if="dislikeCountIsShow"
      >{{ votable.votingDislikeUsersCount }}</span>
    </div>

  </div>
</template>

<script>
import {Message} from "element-ui";
import {mapState} from "vuex";
import pluralize from 'pluralize'

export default {
  name: "Votable",
  props: {
    votableName: {
      type: String,
      default: () => ''
    },
    votable: {
      type: Object,
      default: () => {},
    },
    likeIcon: {
      type: String,
      default: () => 'mdi-thumb-up'
    },
    dislikeIcon: {
      type: String,
      default: () => 'mdi-thumb-down'
    },
    likeColor: {
      type: String,
      default: () => 'green'
    },
    dislikeColor: {
      type: String,
      default: () => 'red'
    },
    iconIsSmall: {
      type: Boolean,
      default: () => true
    },
    dislikeIsShow: {
      type: Boolean,
      default: () => true
    },
    likeCountIsShow: {
      type: Boolean,
      default: () => true
    },
    dislikeCountIsShow: {
      type: Boolean,
      default: () => true
    },
  },
  data() {
    return {
      likeLoading: false,
      dislikeLoading: false,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    url() {
      return pluralize(this.votableName) + '/' + this.votable.id + '/'
    },
  },
  methods: {
    async like() {
      this.likeLoading = true
      try {
        const response = await this.$http.post(this.url + 'likes' )
        Message({
          message: response.data.message,
          center: true,
          type: 'success',
        })
        // console.log(response.data.data)
        this.$emit('update:votable', response.data.data)
      } catch (e) {
        if(e.response.status === 403) {
          Message({
            message: e.response.data.message,
            center: true,
            type: 'error',
          })
        }
      }
      this.likeLoading = false
    },
    async dislike() {
      this.dislikeLoading = true
      try {
        const response = await this.$http.post(this.url + 'dislikes' )
        Message({
          message: response.data.message,
          center: true,
          type: 'success',
        })
        this.$emit('update:votable', response.data.data)
      } catch (e) {
        if(e.response.status === 403) {
          Message({
            message: e.response.data.message,
            center: true,
            type: 'error',
          })
        }
      }
      this.dislikeLoading = false
    },
  },
}
</script>

<style scoped>

</style>