import http from "@/utils/http"
import storage from '@/utils/localStorage'
import sessionStorage from "@/utils/sessionStorage"
import router from "@/router"

const state = {
    user: storage.get('user') || '',
    token: storage.get('token') || sessionStorage.get('token') || '',
    signUpEmail: storage.get('signUpEmail') || '',
    codeKey: storage.get('codeKey') || '',
}

const mutations = {
    updateToken(state, payload) {
        const token = payload.token
        state.token = token
        payload.remember ? storage.set('token', token) : sessionStorage.set('token', token)
    },
    updateRefreshToken(state, refreshToken) {
        state.refreshToken = refreshToken
        storage.set('refreshToken', refreshToken)
    },
    updateUser(state, user) {
        state.user = user
    },
    resetState(state) {
        state.user = ''
        state.token = ''
        state.refreshToken = ''
        storage.remove('token', 'refreshToken', 'user')
        sessionStorage.remove('token')
    },
    updateSignUpEmailAndCodeKey(state, { signUpEmail, codeKey }) {
        state.signUpEmail = signUpEmail
        state.codeKey = codeKey
        storage.set('signUpEmail', signUpEmail)
        storage.set('codeKey', codeKey)
    },
    removeSignUpEmailAndCodeKey(state) {
        state.signUpEmail = ''
        state.codeKey = ''
        storage.remove('signUpEmail', 'codeKey')
    },
}

const actions = {
    async signUp({ commit, dispatch }, data) {
        await http.post('users', data)
    },
    async signIn({ commit, dispatch }, data) {
        // await的作用：当异步请求发生错误时，把异步请求中返回的 Promise.reject(error) 当作该异步函数的返回值，异步函数执行结束。错误由调用函数处理。
        const res = await http.post('tokens', {...data, grant_type:'password'})
        const payload = {
            token: res.data.access_token,
            remember: data.remember
        }
        commit('updateToken', payload)

        if (data.remember) {
            commit('updateRefreshToken', res.data.refresh_token)
        }
        dispatch('getUser')
    },
    async getUser({ commit, state }) {
        if(! state.token) {
            return
        }
        try {
            const res = await http.get('user')
            commit('updateUser', res.data)
        } catch (e) {
            console.log(e)
        }
    },
    async signOut({ commit }, route) {
        try {
            const response = await http.delete('tokens')
        } catch (e) {

        }
        commit('resetState')
        if (route !== undefined) {
            await router.push({ name: 'Signin', params: { redirect: route } })
        } else {
            await router.push({ name: 'Signin' })
        }
    },
}

export default {
    state,
    mutations,
    actions
}