<template>
  <v-app>
    <v-main>
      <v-container
          fluid
          class="create"
      >
        <v-form
            ref="form"
        >
          <v-row
              class="align-center justify-space-around"
          >
            <v-col
                cols="12"
                md="5"
            >
              <v-text-field
                  label="标题"
                  light
                  v-model="form.title"
                  :rules="rules.title"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                md="2"
            >
              <v-file-input
                  chips
                  label="封面图"
                  light
                  accept="image/*"
                  v-model="hero"
                  :rules="rules.hero"
              ></v-file-input>
            </v-col>
            <v-col
                cols="12"
                md="2"
            >
              <v-select
                  label="类别"
                  v-model="form.category_id"
                  :items="categories"
                  item-text="name"
                  item-value="id"
                  :menu-props="{light: true, 'offset-y': true}"
                  :rules="rules.category"
                  light
              ></v-select>
            </v-col>
            <v-col
                md="1"
                cols="12"
            >
              <v-btn
                  width="100%"
                  color="primary"
                  @click="createArticle"
                  :loading="loading"
              >发布</v-btn>
            </v-col>
            <v-col
                md="2"
                cols="12"
            >
              <v-menu
                  offset-y
                  left
                  v-if="user"
                  light
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      text
                      v-bind="attrs"
                      v-on="on"
                      color="black"
                      block
                  >
                    {{ user.name }}
                    <v-icon
                        right
                    >
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list
                    nav
                >
                  <v-list-item
                      :to="{ name: 'User', params: { userId: user.id } }"
                  >
                    <v-list-item-avatar>
                      <v-img
                          :alt="user.name"
                          :src="user.avatar"
                      ></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ user.email }}
                      </v-list-item-title>
                      <v-list-item-subtitle>访问个人主页</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item link>
                    <v-icon>mdi-cog</v-icon>
                    <v-list-item-title>设置</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
        <Editor :value="form.body" placeholder="请使用 Markdown 语法书写" :plugins="plugins" :locale="locale" @change="handleChange" :uploadImages="uploadImages" />
      </v-container>
    </v-main>

  </v-app>
</template>

<script>
import { Editor } from '@bytemd/vue'
import { Message } from 'element-ui'
import { plugins, locale} from "@/plugins/bytemd"
import {mapState} from "vuex";

export default {
  name: "Create",
  components: { Editor },
  data() {
    return {
      form: {
        title: '',
        body: '',
        category_id: 0,
      },
      hero: null,
      categories: [],
      loading: false,
      rules: {
        title: [
          v => !!v || '请输入标题',
        ],
        category: [
          v => !!v || '请选择类别',
        ],
        hero: [
          v => !v || v.size < 5000000 || '封面图文件大小应小于5M'
        ]
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    })
  },
  methods: {
    handleChange(v) {
      this.form.body = v
    },
    async uploadImages(files) {
      try {
        const uploads = files.map(file => {
          const form = new FormData()
          form.append('image', file)
          form.append('type', 'article')
          return this.$http.post('images', form)
        })
        const images = await Promise.all(uploads)
        return images.map(image => {
          return {
            url: image.data.path,
          }
        })

      } catch (e) {
        console.log(e.response)
      }
    },
    async createArticle() {
      if (this.form.body.trim() === '') {
        Message({
          message: '内容不能为空',
          type: 'error',
          center: true,
        })
      } else {
        if (this.$refs.form.validate()) {
          this.loading = true
          let imageId
          try {
            if (this.hero) {
              const form = new FormData()
              form.append('image', this.hero)
              form.append('type', 'article')
              const res = await this.$http.post('images', form)
              imageId = res.data.id
            }
            const data = imageId ? { image_id: imageId, ...this.form } : this.form
            let article
            if(this.$route.params.articleId) {
              article = await this.$http.patch(`articles/${this.$route.params.articleId}`, data)

            } else {
              article = await this.$http.post('articles', data)
            }

            Message({
              message: '文章发布成功！',
              type: 'success',
              center: true,
              onClose: message => {
                this.$router.push({ name: 'ArticleContent', params: { articleId: article.data.id} })
              }
            })
          } catch (e) {
            // console.log(e.response.data.message)
            Message({
              message: e.response.data.message,
              type: 'error',
              center: true,
            })
            this.loading = false
          }
        }
      }

    }
  },
  async created() {
    this.plugins = plugins
    this.locale = locale
    const articleId = this.$route.params.articleId
    if (articleId) {
      const result = await this.$http.get(`articles/${articleId}`)
      const article = result.data
      // console.log(article)
      this.form.title = article.title
      this.form.body = article.body
      this.form.category_id = article.category_id
    }
    try {
      const res = await this.$http.get('categories')
      this.categories = res.data.data
    } catch (e) {
      console.log(e)
    }
  },
}
</script>

<style lang="scss">
@import '~bytemd/dist/index.min.css';
@import "@/styles/markdown.scss";
@import "~katex/dist/katex.min.css";

.bytemd {
  height: calc(100vh - 94px);
}
.create {
  background-color: white !important;
}

</style>