<template>
  <v-container>
    <v-row
        class="justify-center"
    >
      <v-col
          cols="12"
          sm="12"
          md="6"
      >
        <v-card>
          <v-card-title>
            互动规则
          </v-card-title>
          <v-card-subtitle>
            用户不应该：
          </v-card-subtitle>
          <v-card-text>
            <ol>
              <li>反对宪法所确定的基本原则的；</li>
              <li>危害国家安全，泄露国家秘密，颠覆国家政权，推翻社会主义制度，煽动分裂国家，破坏国家统一的；</li>
              <li>损害国家荣誉和利益的；</li>
              <li>宣扬恐怖主义、极端主义的；</li>
              <li>煽动民族仇恨、民族歧视，破坏民族团结的；</li>
              <li>煽动地域歧视、地域仇恨的；</li>
              <li>编造、散布谣言、虚假信息，扰乱经济秩序和社会秩序，破坏社会稳定的；</li>
              <li>危害网络安全、利用网络从事危害国家安全、荣誉和利益的；</li>
              <li>散布污言秽语，损害社会公序良俗的；</li>
              <li>使用本网站常用语言文字以外的其他语言文字评论的；</li>
            </ol>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Rules"
}
</script>

<style scoped>

</style>