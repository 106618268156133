<template>
  <v-card>
    <v-list>
      <template v-for="(article,index) in articles">
        <v-divider
            v-if="index !== 0"
        ></v-divider>

        <v-list-item>
          <v-list-item-avatar
              @click="$router.push({ name: 'ArticleContent', params: { articleId: article.id } })"
              style="cursor: pointer;"
              tile
              size="100"
          >
            <v-img contain :src="article.hero"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
                @click="$router.push({ name: 'ArticleContent', params: { articleId: article.id } })"
                style="cursor: pointer;"
                v-text="article.title"
                class="article-title"
            ></v-list-item-title>
            <v-list-item-subtitle>
              回复数:{{ article.reply_count }}

              <ArticleVotable
                  :votable.sync="articles[index]"
              ></ArticleVotable>
            </v-list-item-subtitle>
          </v-list-item-content>
          {{ article.created_at }}
        </v-list-item>
      </template>

      <v-row
          v-intersect="onscroll"
          v-if="url"
      >
        <v-col
            v-for="i in 4"
            cols="12"
        >
          <v-skeleton-loader
              type="table-heading"
              elevation="2"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

    </v-list>

  </v-card>
</template>

<script>
import ArticleVotable from '@/components/ArticleVotable'

export default {
  name: "LikeArticlesList",
  components:  {
    ArticleVotable,
  },
  data: () => ({
    articles: [
    ],
    url: '',
  }),
  methods: {
    async getArticles(url) {
      const result = await this.$http.get(url)
      this.url = result.data.links.next
      return result.data.data
    },
    async onscroll(entries, observer) {
      if(entries[0].isIntersecting) {
        if (this.url !== null) {
          const articles = await this.getArticles(this.url)
          this.articles.push(...articles)
        }
      }
    }
  },
  created() {
    this.url = 'users/' + this.$route.params.userId + '/voted-like-articles'
  }
}
</script>

<style scoped>
 .article-title{
   white-space: initial !important;
 }
</style>