import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import storage from '@/utils/localStorage'
import http from "@/utils/http"

Vue.use(Vuex)

const state = {
  dark: storage.get('dark') ?? true,
  locale: storage.get('locale') ?? 'zhHans',
  categories: storage.get('categories') || [],
}

const mutations = {
  toggleDark(state) {
    state.dark = !state.dark
    storage.set('dark', state.dark)
  },
  changeLocale(state, locale) {
    state.locale = locale
    storage.set('locale', state.locale)
  },
  updateCategories(state, categories) {
    state.categories = categories
    storage.set('categories',state.categories)
  }
}

const actions = {
  async getCategories({ commit }) {
    try {
      const res = await http.get('categories')
      commit('updateCategories', res.data.data)
    } catch (e) {
      console.log(e)
    }
  }
}

export default new Vuex.Store({
  modules: {
    user,
  },
  state,
  mutations,
  actions,
})
