<template>
  <v-app>
    <TheHeader
        v-on:toggleDrawer="drawer = ! drawer"
        :links="categories"
    />
    <TheDrawer
        v-model="drawer"
        :links="categories"
    />
    <v-main>
      <EmailForm v-if="!token &&$route.name ==='Home'" v-cloak/>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
    <FabToTop/>
    <TheFooter></TheFooter>
  </v-app>
</template>

<script>
  import TheHeader from "@/components/TheHeader"
  import TheFooter from "@/components/TheFooter"
  import TheDrawer from "@/components/TheDrawer"
  import Snackbars from "@/components/Snackbars"
  import FabToTop from "@/components/FabToTop"
  import EmailForm from "@/components/EmailForm"
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'Home',
    computed: {
      ...mapState({
        token: state => state.user.token,
        categories: state => {
          let categories = [ { name: '首页', slug: 'Home', description: '' } ]
          categories.push(...state.categories)
          return categories
        }
      })
    },
    components: {
      TheHeader,
      TheFooter,
      TheDrawer,
      Snackbars,
      FabToTop,
      EmailForm,
    },
    data: () => ({
      drawer: false,
    }),
    created() {
      this.getCategories()
    },
    methods: {
      ...mapActions([
          'getCategories',
      ]),
    }
  }
</script>

<style lang="scss" scoped>

</style>
