import zhHans from './lib/locales/zh_Hans.json'

const styleList = [
    'atelier-forest-dark',
    'atelier-heath-dark',
    'atelier-lakeside-dark',
]
function getStyleFun(theme) {
    let style
    try {
        style = require(`highlight.js/styles/${theme}.css`)
    } catch (e) {
        style = require('highlight.js/styles/a11y-dark.css')
    }
    return style.default
}
export default function highlightStyle(_locale) {
    let locale = require(`./lib/locales/${_locale}.json`)
    locale = {...zhHans, ...locale}
    return {
        viewerEffect({ file }) {
            const hl = file?.frontmatter?.highlight
            const theme = hl ?? 'a11y-dark'
            let style = getStyleFun(theme)
            style.use()
            return  () => {
                style.unuse()
            }
        },

        actions: [
            {
                title: locale.highlightStyle,
                icon: '<?xml version="1.0" encoding="UTF-8"?><svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01"/><path d="M6 44L6 25H12V17H36V25H42V44H6Z" fill="none" stroke="#333" stroke-width="4" stroke-linejoin="round"/><path d="M17 17V8L31 4V17" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>', // 显示在工具栏上的icon,
                handler: {
                    type: 'dropdown',
                    actions: styleList.map(styleName => ({
                        title: styleName,
                        handler: {
                            type: 'action',
                            click({ editor }) {
                                const originalBody = editor.getValue()
                                let body = originalBody.replace(/---\n.*\n---\n/g, '')
                                body  = `---\nhighlight: ${styleName}\n---\n` + body
                                editor.setValue(body)
                                editor.focus()
                            }
                        }
                    }))
                }
            }
        ]
    }
}