<template>
  <v-container>
    <v-row
        class="justify-center"
    >
      <v-col
          cols="12"
          sm="6"
          md="4"
      >
        <v-card>
          <v-img
              :src="require('@/assets/wechat.jpg')"
          ></v-img>
          <v-card-text class="text-center">
            加好友请先表明来意
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Contact"
}
</script>

<style scoped>

</style>