<template>
  <v-card>
    <v-list>
      <template v-for="(article,index) in articles">
        <v-divider
            v-if="index !== 0"
        ></v-divider>

        <v-list-item
            :to="{ name: 'ArticleContent', params: { articleId: article.id } }"
        >
          <v-list-item-avatar  tile size="100">
            <v-img contain :src="article.hero"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
                v-text="article.title"
                class="article-title"
            ></v-list-item-title>
            <v-list-item-subtitle>
              回复数:{{ article.reply_count }}&nbsp;&nbsp;点赞数:{{ article.like_count }}
            </v-list-item-subtitle>
          </v-list-item-content>
          {{ article.created_at }}
        </v-list-item>
      </template>

      <v-row
          v-intersect="onscroll"
          v-if="url"
      >
        <v-col
            v-for="i in 4"
            cols="12"
        >
          <v-skeleton-loader
              type="table-heading"
              elevation="2"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-list>

  </v-card>
</template>

<script>
export default {
  name: "ArticlesList",
  data: () => ({
    articles: [
    ],
    url: '',
  }),
  methods: {
    async getArticles(url) {
      const articlesResult = await this.$http.get(url, { params: { include: 'category'} })
      this.url = articlesResult.data.links.next
      return articlesResult.data.data
    },
    async onscroll(entries, observer) {
      if(entries[0].isIntersecting) {
        if (this.url !== null) {
          const articles = await this.getArticles(this.url)
          this.articles.push(...articles)
        }
      }
    }
  },
  created() {
    this.url = 'users/' + this.$route.params.userId + '/articles'
  }
}
</script>

<style scoped>
 .article-title{
   white-space: initial !important;
 }
</style>