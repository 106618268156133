<template>
  <v-app-bar
      class="v-bar--underline"
      :height="this.$vuetify.breakpoint.mdAndUp ? 64 : ''"
      flat
      absolute
      app
  >
    <v-app-bar-nav-icon @click="$emit('toggleDrawer')" :class="{ 'd-none': this.$vuetify.breakpoint.mdAndUp }"></v-app-bar-nav-icon>

    <div class="d-flex flex-grow-1 align-center" :class="{ 'justify-center': !this.$vuetify.breakpoint.mdAndUp, 'bar-content': !this.$vuetify.breakpoint.mdAndUp }">
      <router-link
          :to="{ name:'Home' }"
          class="d-inline-block"
      >
        <v-img
            class=""
            :src="require('@/assets/logo.svg')"
            max-width="34"
            transition="scale-transition"
        >
        </v-img>
      </router-link>

      <div class="d-md-flex flex-grow-1 hidden-md-and-down align-center ml-6">
        <v-btn
            v-for="(link, index) of links"
            :to="{ name: link.slug}"
            exact
            :key="index"
            tile
            plain
            width="90"
            x-large
        >
          {{ link.name }}
        </v-btn>

        <v-spacer></v-spacer>

        <v-tooltip
            bottom
        >
          <template
              v-slot:activator="{ on, attrs }"
          >
            <v-btn
                icon
                :to="{ name: 'CreateArticle' }"
                v-on="on"
                v-bind="attrs"
            >
              <v-icon
                  color="light-green"
              >mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>写新文章</span>
        </v-tooltip>

        <v-menu
            offset-y
            left
            open-on-hover
            transition="slide-y-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                text
                v-bind="attrs"
                v-on="on"
            >
              <v-icon
                  right
              >
                mdi-translate
              </v-icon>
              <v-icon
                  right
              >
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>
          <v-list
              nav
              dense
              expand
          >
            <v-subheader
                class="text--primary font-weight-black text-uppercase"
            >
              切换语言
            </v-subheader>
            <v-list-item-group
                :value="$vuetify.lang.current"
            >
              <v-list-item
                  v-for="(title, locale) in locales"
                  :key="locale"
                  @click="changeLocale(locale)"
                  :value="locale"
                  link>
                <v-list-item-title>{{ title }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <v-tooltip
            bottom
        >
          <template
              v-slot:activator="{ on, attrs }"
          >
            <v-btn
                icon
                @click="toggleDark"
                v-on="on"
                v-bind="attrs"
            >
              <v-icon>mdi-theme-light-dark</v-icon>
            </v-btn>
          </template>
          <span>{{ $vuetify.theme.dark ? "深色" : "浅色" }}</span>
        </v-tooltip>

        <v-menu
            offset-y
            left
            v-if="user"
            open-on-hover
            transition="slide-y-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-badge
                :value="user.notification_count > 0"
                dot
                color="red"
                overlap
            >
              <v-btn
                  text
                  v-bind="attrs"
                  v-on="on"
              >
                {{ user.name }}
                <v-icon
                    right
                >
                  mdi-chevron-down
                </v-icon>
              </v-btn>

            </v-badge>
          </template>
          <v-list
              nav
          >
            <v-list-item
                :to="{ name: 'User', params: { userId: user.id } }"
            >
              <v-list-item-avatar>
                <v-img
                    :alt="user.name + ' avatar'"
                    :src="user.avatar"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  {{ user.email }}
                </v-list-item-title>
                <v-list-item-subtitle>访问个人主页</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item
                @click.prevent="$router.push({ name: 'notifications' })"
                class="align-center"
                link>
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon class="mr-2">mdi-alarm-light-outline</v-icon>
                  <v-badge
                      :value="user.notification_count > 0"
                      :content="user.notification_count"
                      color="red"
                  >
                    我的消息
                  </v-badge>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                :to="{ name: 'UserEdit' }"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon class="mr-2">mdi-cog</v-icon>
                  设置
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                @click="signOut($route)"
                link>
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon class="mr-2">mdi-logout</v-icon>
                  退出
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
            v-else
            :to="{ name: 'Signin' }"
            tile
            plain
            width="90"
            x-large
        >
          登录
        </v-btn>

      </div>


    </div>
  </v-app-bar>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: "TheHeader",
  props: {
    links: {
      type: Array,
      default: [],
    }
  },
  data() {
    return {
      drawer: false,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  methods: {
    ...mapActions([
        'signOut',
    ]),
    toggleDark() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.$store.commit('toggleDark')
    },
    changeLocale(locale) {
      this.$vuetify.lang.current = locale
      this.$store.commit('changeLocale', locale)
    },
  },
  created() {
    this.locales = {
      // af: "Afrikaans",
      // ar: "اللغة العربية",
      // az: "Azərbaycan",
      // bg: "български",
      // ca: "català",
      // ckb: "کوردی",
      // cs: "čeština",
      // de: "Deutsch",
      // el: "Ελληνικά",
      en: "English",
      // es: "Español",
      // et: "eesti",
      // fa: "فارسی",
      // fi: "suomi",
      // fr: "Français",
      // he: "עברית",
      // hr: "hrvatski jezik",
      // hu: "magyar",
      // id: "Indonesian",
      // it: "Italiano",
      // ja: "日本語",
      // ko: "한국어",
      // lt: "lietuvių kalba",
      // lv: "latviešu valoda",
      // nl: "Nederlands",
      // no: "Norsk",
      // pl: "język polski",
      // pt: "Português",
      // ro: "Română",
      // ru: "Русский",
      // sk: "slovenčina",
      // sl: "slovenski jezik",
      // srCyrl: "српски језик",
      // srLatn: "srpski jezik",
      // sv: "svenska",
      // th: "ไทย",
      // tr: "Türkçe",
      // uk: "Українська",
      // vi: "Tiếng Việt",
      zhHans: "简体中文",
      // zhHant: "正體中文",
    }
  }
}
</script>

<style>
.bar-content {
  margin-left: -36px;
}

.theme--dark .v-bar--underline, .theme--light .v-bar--underline {
  border-width: 0 0 thin;
  border-style: solid;
}
.theme--dark .v-bar--underline.theme--light, .theme--light .v-bar--underline.theme--light {
  border-bottom-color: rgba(0,0,0,.12)!important;
}
</style>
