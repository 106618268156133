<template>
  <v-container>
    <v-row>
      <v-col
          cols="12"
          md="3"
          :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
      >
        <v-card
            class="d-flex flex-column align-center"
        >
          <v-card-title
          >
            <v-avatar
                size="100"
            >
              <img :src="user.avatar" :alt="user.name">
            </v-avatar>
          </v-card-title>

          <v-card-subtitle
              class="mt-2"
          >
            <h2>
              {{ user.name }}
            </h2>
          </v-card-subtitle>

          <v-card-actions>
            <UserVotable
                v-bind:votable.sync="user"
                :likeCountIsShow="true"
            ></UserVotable>
          </v-card-actions>

          <v-card-text
              class="text-center"
          >
            <p>加入于&nbsp;&nbsp;&nbsp;&nbsp;{{ user.created_at}}</p>
            <p>最后活跃于&nbsp;&nbsp;&nbsp;&nbsp;{{ user.updated_at}}</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
          cols="12"
          md="9"
          :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
      >
        <v-card>
          <v-tabs
              show-arrows
          >
            <v-tab>
              文章
            </v-tab>
            <v-tab>
              {{ user.id === authUser.id ? '我' : 'TA' }}的评论
            </v-tab>
            <v-tab v-if="user.id === authUser.id">
              回复我的
            </v-tab>
            <v-tab>
              关注的人
            </v-tab>
            <v-tab>
              关注{{ user.id === authUser.id ? '我' : 'TA' }}的
            </v-tab>
            <v-tab>
              赞的文章
            </v-tab>

            <v-tab-item>
              <ArticlesList></ArticlesList>
            </v-tab-item>
            <v-tab-item>
              <RepliesList></RepliesList>
            </v-tab-item>
            <v-tab-item v-if="user.id === authUser.id">
              <ReceivedRepliesList></ReceivedRepliesList>
            </v-tab-item>
            <v-tab-item>
              <VotedLikeUsersList></VotedLikeUsersList>
            </v-tab-item>
            <v-tab-item>
              <VotingLikeUsersList></VotingLikeUsersList>
            </v-tab-item>
            <v-tab-item>
              <LikeArticlesList></LikeArticlesList>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import ArticlesList from '@/views/articles/List'
import RepliesList from '@/views/replies/List'
import ReceivedRepliesList from '@/views/replies/ReceivedRepliesList'
import VotedLikeUsersList from "@/views/users/VotedLikeUsersList"
import VotingLikeUsersList from "@/views/users/VotingLikeUsersList"
import LikeArticlesList from "@/views/articles/LikeArticlesList"
import UserVotable from "@/components/UserVotable"
import { mapState } from "vuex";

export default {
  name: "Index.vue",
  data () {
    return {
      user: {},
    }
  },
  components: {
    ArticlesList,
    RepliesList,
    ReceivedRepliesList,
    VotedLikeUsersList,
    VotingLikeUsersList,
    LikeArticlesList,
    UserVotable,
  },
  computed: {
    ...mapState({
      authUser: state => state.user.user,
    }),
  },
  methods: {
    async getUser(userId) {
      try {
        const { data: user } = await this.$http.get(`users/${userId}?`)

        this.user = user
      } catch (e) {
        console.log(e)
      }
    },
  },
  created() {
    this.getUser(this.$route.params.userId)
  }
}
</script>

<style scoped>

</style>