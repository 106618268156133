const ls = sessionStorage

export default {
    set(name, value) {
        ls.setItem(name, JSON.stringify(value))
    },
    get(name) {
        try {
            return JSON.parse(ls.getItem(name))
        } catch (e) {
            return null
        }
    },
    remove(...items) {
        items.forEach( item => {
                ls.removeItem(item)
            }
        )
    }
}