<template>
  <router-view/>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapState({
      token: state => state.user.token,
    })
  },
  methods: {
    ...mapActions([
        'getUser',
    ])
  },
  created() {
    this.$vuetify.theme.dark = this.$store.state.dark
    this.$vuetify.lang.current = this.$store.state.locale
    if (this.token) {
      this.getUser()
    }
  },
};
</script>

<style lang="scss">
[v-cloak] {
  display: none !important;
}
</style>
