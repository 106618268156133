import store from '@/store'
import gfm from '@bytemd/plugin-gfm'
import highlight from '@bytemd/plugin-highlight'
import frontmatter from '@bytemd/plugin-frontmatter'
import gemoji from '@bytemd/plugin-gemoji'
import math from '@bytemd/plugin-math'
import mermaid from '@bytemd/plugin-mermaid'
import footnotes from '@bytemd/plugin-footnotes'
import breaks from '@bytemd/plugin-breaks'
import highlightStyle from '@/plugins/highlightStyle'

function getPluginLocale(plugin, locale) {
    return require(`@bytemd/plugin-${plugin}/lib/locales/${locale}.json`)
}

function getByteMdLocale(locale) {
    return require(`bytemd/lib/locales/${locale}.json`)
}

function camelcaseToUnderLine(string) {
    return string.replace(/([A-Z])/g, "_$1")
}

const vuetifyLocale = camelcaseToUnderLine(store.state.locale)

function setPluginLocale(plugin, name) {
    let locale = getPluginLocale(name, vuetifyLocale)
    return plugin({ locale })
}

const plugins = [
    setPluginLocale(gfm, 'gfm'),
    setPluginLocale(math, 'math'),
    setPluginLocale(mermaid, 'mermaid'),
    highlight(),
    frontmatter(),
    gemoji(),
    footnotes(),
    breaks(),
    highlightStyle(vuetifyLocale),
]

const locale = getByteMdLocale(vuetifyLocale)

export { plugins, locale }