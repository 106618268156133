<template>
  <Votable
      votableName="article"
      v-bind="$attrs"
      v-on="$listeners"
  ></Votable>
</template>

<script>
import Votable from "@/components/Votable"
export default {
  name: "ArticleVotable",
  components: {
    Votable,
  },
}
</script>

<style scoped>

</style>