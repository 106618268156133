<template>
  <v-card>
    <v-list>
      <template v-for="(reply,index) in replies">
        <v-divider
            v-if="index !== 0"
        ></v-divider>

        <v-list-item>
          <v-list-item-avatar
              class="align-self-start"
          >
            <v-img contain :src="reply.user.avatar"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
                v-text="reply.content"
            ></v-list-item-title>

            <v-card>
              <v-list-item
                  @click="$router.push({ path: `/articles/${reply.article.id}#reply` })"
              >
                <v-list-item-avatar  tile size="100">
                  <v-img contain :src="reply.article.hero"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                      class="article-title"
                      v-text="reply.article.title"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>

          </v-list-item-content>

          <v-list-item-action>
            <v-list-item-action-text v-text="reply.created_at"></v-list-item-action-text>
            <v-icon
            >

            </v-icon>
          </v-list-item-action>

        </v-list-item>
      </template>


      <v-row
          v-intersect="onscroll"
          v-if="url"
      >
        <v-col
            v-for="i in 4"
            cols="12"
        >
          <v-skeleton-loader
              type="list-item-avatar-two-line, card-heading"
              elevation="2"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-list>
  </v-card>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "ReplyList",
  data: () => ({
    replies: [
    ],
  }),
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
  },
  methods: {
    async getReplies(url) {
      const repliesResult = await this.$http.get(url, { params: { include: 'user,article'} })
      this.url = repliesResult.data.links.next
      return repliesResult.data.data
    },
    async onscroll(entries, observer) {
      if(entries[0].isIntersecting) {
        if (this.url !== null) {
          const replies = await this.getReplies(this.url)
          this.replies.push(...replies)
        }
      }
    }
  },
  created() {
    this.url = 'users/' + this.$route.params.userId + '/replies'
  },
}
</script>

<style scoped>
  .article-title{
    white-space: initial !important;
  }
</style>