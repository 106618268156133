<template>
  <v-container>
    <my-upload field="image"
               @crop-success="cropSuccess"
               @crop-upload-success="cropUploadSuccess"
               @crop-upload-fail="cropUploadFail"
               v-model="imageCrop.show"
               :width="300"
               :height="300"
               :url="`${imageCrop.baseURL}/images`"
               :params="imageCrop.params"
               :headers="{ Authorization: 'Bearer ' + token, Accept: 'application/json', }"
               img-format="png"
               noSquare
    ></my-upload>
    <v-row>
      <v-col
          cols="12"
          md="3"
          :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
      >
        <v-card
            class="d-flex flex-column align-center"
        >
          <v-card-title
          >
            <v-avatar
                size="100"
            >
              <img :src="user.avatar" :alt="user.name">
            </v-avatar>
          </v-card-title>

          <v-card-subtitle
              class="mt-2"
          >
            <h2>
              {{ user.name }}
            </h2>
          </v-card-subtitle>

          <v-card-text
              class="text-center"
          >
            <p>创建时间：{{ user.created_at}}</p>
            <p>最后活跃时间：{{ user.updated_at}}</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
          cols="12"
          md="9"
          :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
      >
        <v-card>
          <v-tabs :vertical="$vuetify.breakpoint.smAndDown ? false : true">
            <v-tab>
              <v-icon left>
                mdi-account
              </v-icon>
              个人信息
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-image
              </v-icon>
              修改头像
            </v-tab>
            <v-tab
                :to="{ name: 'ResetPassword' }"
            >
              <v-icon left>
                mdi-lock
              </v-icon>
              修改密码
            </v-tab>

            <v-tab-item>
              <v-card
                  max-width="400"
                  flat>
                <v-card-text>
                  <v-form
                      ref="form"
                      :value="true"
                      lazy-validation
                  >
                    <v-text-field
                        v-model="form.name"
                        @focus="asyncErrorMessage.name = ''"
                        :error-messages="asyncErrorMessage.name"
                        :rules="rules.name"
                        label="用户名"

                        required
                    ></v-text-field>

                    <v-text-field
                        v-model="form.email"
                        :rules="rules.email"
                        label="E-mail"
                        @focus="asyncErrorMessage.email = ''"
                        :error-messages="asyncErrorMessage.email"
                        required
                    ></v-text-field>

                    <v-btn
                        color="success"
                        class="mr-4"
                        @click="validate"
                    >
                      确认
                    </v-btn>

                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text
                    class="d-flex flex-column align-center"
                >
                  <v-avatar
                      size="200"
                  >
                    <img :src="imageCrop.imgDataUrl ? imageCrop.imgDataUrl : user.avatar">
                  </v-avatar>
                  <v-btn
                      @click="toggleShow"
                      large
                      color="success"
                      dark
                      class="mt-4"
                  >
                    上传头像
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>


</template>

<script>
import { mapState } from 'vuex'
import { Message } from 'element-ui'
import myUpload from 'vue-image-crop-upload/upload-2'

export default {
  name: "Edit",
  computed: {
    ...mapState({
      user: state => state.user.user,
      token: state => state.user.token,
    }),
    form: function () {
      return {
        name: this.user.name,
        email: this.user.email,
      }
    }
  },
  data: () => ({
    loading: false,
    rules: {
      name: [
        v => !!v || '请输入用户名',
      ],
      email: [
        v => !!v || '请输入电子邮箱',
      ],
    },
    asyncErrorMessage: {
      name: '',
      email: '',
    },
    imageCrop: {
      show: false,
      params: {
        type: 'avatar',
      },
      imgDataUrl:'',
      baseURL: '',
    }
  }),
  components: {
    'my-upload': myUpload,
  },
  created() {
    this.imageCrop.baseURL = process.env.VUE_APP_BASE_API
  },
  methods: {
    toggleShow() {
      this.imageCrop.show = !this.imageCrop.show;
    },

    cropSuccess(imgDataUrl, field){
      // console.log('-------- crop success --------');
      this.imageCrop.imgDataUrl = imgDataUrl;
    },
    /**
     * upload success
     *
     * [param] jsonData   服务器返回数据，已进行json转码
     * [param] field
     */
    async cropUploadSuccess(jsonData, field){
      // console.log('-------- upload success --------')
      await this.updateUser({ avatar_id: jsonData.id })
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field){
      // console.log('-------- upload fail --------');
      console.log(status);
      // console.log('field: ' + field);
    },
    async validate () {
      if (this.$refs.form.validate()) {
        this.loading = true
        await this.updateUser(this.form)
        this.loading = false
      }
    },
    async updateUser(data) {
      try {
        await this.$http.patch('users', data)
        Message({
          message: '信息更新成功',
          type: 'success',
        })
      } catch (e) {
        if (e.response) {
          const res = e.response
          if (res.status === 422) {
            if(res.data.errors && res.data.errors.name) {
              this.asyncErrorMessage.name = res.data.errors.name
            }
            if(res.data.errors && res.data.errors.email) {
              this.asyncErrorMessage.email = res.data.errors.email
            }
            if(res.data.errors && res.data.errors.avatar_id) {
              Message({
                message: '图片有误',
                type: 'error',
              })
            }
          } else {
            console.log(res)
          }
        } else {
          console.log(e)
        }
      }
    }
  },
}
</script>

<style>
 .vicp-wrap{
   max-width: 100% !important;
 }
</style>