<template>
  <v-expand-x-transition>
    <v-list-item
        :key="reply.id"
        class="align-start justify-start"
        :id="'reply' + reply.id"
    >
      <v-list-item-avatar
          class="align-self-start"
          @click="$router.push({ name: 'User', params: { userId: reply.user.id } })"
          style="cursor: pointer"
      >
        <v-img :src="reply.user.avatar"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          <span @click="$router.push({ name: 'User', params: { userId: reply.user.id } })"
                style="cursor: pointer">{{ reply.user.name }}</span>
          <template
              v-if="reply.parent_id > 0"
          >
            <span>@</span>
            <router-link
                :to="{ name: 'User', params: { userId: reply.parent.user.id } }"
                style="text-decoration: none !important;"
            >
              {{ reply.parent.user.name }}
            </router-link>
          </template>
        </v-list-item-title>
        <p
            class="my-3 body-2"
        >
          {{ reply.content }}
        </p>
        <v-list-item-subtitle
            class="d-flex align-content-center"
            style="height: 40px;"
        >
          {{ reply.created_at }}

          <v-spacer></v-spacer>

          <Votable
              v-on="$listeners"
              v-bind="$attrs"
              votableName="reply"
          ></Votable>

          <v-btn
              icon
              @click="showReplyDialog()"
          >
            <v-icon
                small
            >
              mdi-reply
            </v-icon>
          </v-btn>
        </v-list-item-subtitle>

        <v-sheet
            rounded
            v-if="reply.replies && reply.replies.length > 0"
            :color="secondRelyBackColor"
            class="mt-2"
        >
          <v-list-item-content>
            <template
                v-for="(item, index) of reply.replies"
            >
              <v-divider
                  inset
                  v-if="index !== 0"
              ></v-divider>
              <Reply
                  :reply="item"
                  :rootReply="rootReply"
                  @showReplyDialog="showReplyDialog"
                  v-bind:votable.sync="reply.replies[index]"
              >
              </Reply>


            </template>
          </v-list-item-content>
        </v-sheet>

      </v-list-item-content>
    </v-list-item>
  </v-expand-x-transition>
</template>

<script>
import { mapState } from "vuex";
import Reply from '@/components/Reply'
import Votable from "@/components/Votable";

export default {
  name: "Reply",
  components: {
    Reply,
    Votable,
  },
  props: {
    reply: {
      type: Object,
      default: () => {},
    },
    // 新回复要挂载的根一级回复
    rootReply: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      hasReplies: true,
      replyValid: false,
      replyContent: '',
      replyRules: [
        v => !!v || '请输入回复',
      ],
      loading: false,
    }
  },
  methods: {
    showReplyDialog(rootReply, reply) {
      // 这里传过来的rootReply, reply是 二级回复中的rootReply
      this.$emit('showReplyDialog', rootReply || this.rootReply, reply || this.reply)
    },
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    secondRelyBackColor() {
      return this.$vuetify.theme.dark ? "grey darken-3" : "grey lighten-4"
    },
  },
  created() {

  }
}
</script>

<style scoped>

</style>