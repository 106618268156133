<template>
  <v-img
      :src="require('@/assets/signup_background.jpg')"
      max-height="400"
      style="z-index: 2;"
  >
    <Verify
        @success="success"
        mode="pop"
        :captchaType="captchaType"
        :imgSize="{ width: '330px', height: '155px' }"
        ref="verify"
    ></Verify>
    <v-container
        class="fill-height flex-column justify-start align-stretch"
    >
      <h2 class="mx-2 text-center white--text">再微小的个体</h2>
      <h2 class="mx-2 text-center white--text">也有值得分享的时刻</h2>
      <h3 class="ma-4 text-center white--text">随时随地分享</h3>
      <v-form
          ref="form"
          class="align-stretch"
      >
        <h4 class="my-3 text-center white--text">输入您的邮箱即可加入我们</h4>
        <v-row
            no-gutters
            align="start"
            justify="center"
        >
          <v-col
              cols="12"
              sm="4"
              offset-sm="2"
          >
            <v-text-field
                :rules="emailRules"
                v-model="form.email"
                label="电子邮箱"
                filled
                validate-on-blur
                background-color="white"
                class="white-font"
                @focus="asyncErrorMessage.email = ''"
                :error-messages="asyncErrorMessage.email"
            >
            </v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="4"
          >
            <v-btn
                color="rgb(229, 9, 20)"
                :height="$vuetify.breakpoint.smAndUp ? 56 : ''"
                tile
                class="px-8 ms-sm-1 mx-auto d-block"
                :x-large="$vuetify.breakpoint.smAndUp ? true : false"
                @click="validate()"
                :loading="loading"
            >
              <span class="create-btn">
                创建账户&nbsp;<v-icon>mdi-greater-than</v-icon>
              </span>
            </v-btn>
          </v-col>


        </v-row>

      </v-form>
    </v-container>

  </v-img>
</template>

<script>
import { Message } from 'element-ui'
import Verify from "@/components/verifition/Verify"

export default {
  name: "EmailForm",
  data() {
    return {
      form: {
        email: '',
      },
      loading: false,
      asyncErrorMessage: {
        email: ''
      },
      emailRules: [
        v => !!v || '请输入电子邮箱',
        v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(v).toLowerCase()) || '请输入有效的电子邮箱',
      ],
      captchaType: ['clickWord', 'blockPuzzle'][Math.floor(Math.random()*2)],
    }
  },
  components: {
    Verify,
  },
  methods: {
    success(captchaVerification){
      this.sendEmailCode(captchaVerification)
    },
    useVerify(){
      this.$refs.verify.show()
    },
    async validate() {
      if (this.$refs.form.validate()) {
        await this.sendEmailCode()
      }
    },
    async sendEmailCode(captchaVerification) {
      let data = this.form
      if (captchaVerification) {
        data = Object.assign({}, data, captchaVerification)
      }
      this.loading = true
      try {

        const res = await this.$http.post('email-first-verification-code', data)
        // 在localStorage 和 store 中存入验证通过的 email 和返回的 codeKey
        this.$store.commit('updateSignUpEmailAndCodeKey', {
          'signUpEmail': this.form.email,
          'codeKey': res.data.key,
        })
        // console.log(res)
        Message({
          message: '我们已经向您的电子邮箱发送了验证码。',
          type: 'success',
          center: true,
          onClose: message => {
            this.$router.push({ name: 'Signup' })
          }
        })
      } catch (e) {
        if (e.response && e.response.status) {
          if (e.response.status === 422) {
            this.asyncErrorMessage.email = e.response.data.errors.email
          } else if (e.response.status === 429) {
            this.useVerify()
          } else if (e.response.status === 403) {
            Message({
              message: e.response.data.repMsg,
              type: 'error',
              center: true,
            })
          } else {
            console.log(e.response)
          }
        }
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
  .white-font.theme--dark {
    input {
      color: rgba(0, 0, 0, 0.87) !important;
    }
    label {
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .create-btn {
    font-size: 18px;
    color: white;
    display: flex;
    align-items: center;
  }
</style>