<template>
  <v-navigation-drawer
      disable-resize-watcher
      :value="value"
      v-on:input="$emit('input', $event)"
      fixed
      tag="nav"
      app
  >

    <template slot="prepend">
      <v-app-bar class="v-bar--underline" flat>
        <router-link
            :to="{ name:'Home' }"
            class="flex-grow-1 justify-center d-flex"
        >
          <v-img
              class=""
              :src="require('@/assets/logo.svg')"
              max-width="34"
              transition="scale-transition"
          >
          </v-img>
        </router-link>
      </v-app-bar>
    </template>

    <v-list
        nav
    >
      <v-list-group
          v-model="active"
          v-if="user"
      >
        <template
            v-slot:activator
        >
          <v-list-item
          >
            <v-list-item-avatar
                @click="$router.push({ name: 'User', params: { userId: user.id } })"
            >
              <v-img :src="user.avatar"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                  @click="$router.push({ name: 'User', params: { userId: user.id } })"
              >
                <v-badge
                    :value="user.notification_count > 0"
                    dot
                    color="red"
                >
                  {{ user.name }}
                </v-badge>
              </v-list-item-title>
              <v-list-item-subtitle>访问个人主页</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item
            :to="{ name: 'notifications' }"
        >
          <v-list-item-icon>
            <v-icon>mdi-alarm-light-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <v-badge
                  :value="user.notification_count > 0"
                  :content="user.notification_count"
                  color="red"
                  inline
                  class="mt-0"
              >
                我的消息
              </v-badge>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            :to="{ name: 'UserEdit' }"
        >
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              设置
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            @click="signOut($route)"
            link
        >
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              退出
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-item
          v-else
          :to="{ name: 'Signin' }"
      >
        <v-list-item-icon>
          <v-icon>mdi-login</v-icon>
        </v-list-item-icon>
        <v-list-item-title>登录</v-list-item-title>
      </v-list-item>

      <v-divider class="mb-2"></v-divider>

      <v-list-item-group
      >
        <v-list-item
            v-for="(link, index) of links"
            :key="index"
            :to="{ name: link.slug }"
            exact
        >
          <v-list-item-title
              class="text-center"
          >{{ link.name }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>

      <v-divider class="mb-2"></v-divider>

      <v-list-item
          :to="{ name: 'CreateArticle' }"
      >
        <v-list-item-icon>
          <v-icon>mdi-pencil</v-icon>
        </v-list-item-icon>
        <v-list-item-title>发布文章</v-list-item-title>
      </v-list-item>

      <v-list-item
          @click="toggleDark"
      >
        <v-list-item-icon>
          <v-icon>mdi-theme-light-dark</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $vuetify.theme.dark ? "深色" : "浅色" }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: "TheDrawer",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    links: {
      type: Array,
      default: [],
    }
  },
  data() {
    return {
      active: false,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    })
  },
  methods: {
    ...mapActions([
      'signOut',
    ]),
    toggleDark() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.$store.commit('toggleDark')
    }
  },
}
</script>

<style scoped>

</style>
