<template>
  <v-fab-transition>
    <v-btn
        v-show="fab"
        v-scroll="scrollHandler"
        aria-label="scroll to top"
        title="scroll to top"
        bottom
        right
        fab
        fixed
        style="z-index: 6"
        class="transition-swing"
        :class="{ fab: $vuetify.breakpoint.smAndDown }"
        @click="toTop"
        color="transparent"
    >
      <v-icon>
        mdi-chevron-up
      </v-icon>
    </v-btn>
  </v-fab-transition>
</template>

<script>
export default {
  name: "FabToTop",
  data() {
    return {
      fab: false,
    }
  },
  methods: {
    scrollHandler() {
      const top = window.scrollY || document.documentElement.offsetTop || 0

      this.fab = top > 300
    },
    toTop() {
      if (this.$route.hash) {
        this.$router.push({ hash: '' })
      }

      this.$vuetify.goTo(0)
    }
  },
}
</script>

<style scoped>
  .fab {
    bottom: 66px;
  }
</style>