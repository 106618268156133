<template>
  <v-card>
    <v-list>
      <template v-for="(reply,index) in replies">
        <v-divider
            v-if="index !== 0"
        ></v-divider>

        <v-list-item
            two-line
        >
          <v-list-item-avatar
              class="align-self-start"
          >
            <v-img contain :src="reply.user.avatar"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
                v-text="reply.user.name"
            ></v-list-item-title>
            <v-list-item-subtitle
                class="mt-2"
            >
              {{ reply.parent ? '回复' : '评论'}}了我的{{ reply.parent ? '评论' : '文章'}}
            </v-list-item-subtitle>
            <v-list-item-subtitle
                class="text-h6 mt-2"
                v-text="reply.content"
            ></v-list-item-subtitle>

            <v-card
                :to="{ path: `/articles/${reply.article.id}#reply` }"
            >
              <v-card-subtitle
                  class="blue--text text--darken-2"
              >
                @原文：{{ reply.article.title }}
              </v-card-subtitle>
              <v-card-text v-if="reply.parent">
                我的评论： {{ reply.parent.content }}
              </v-card-text>
            </v-card>


          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-action-text v-text="reply.created_at"></v-list-item-action-text>
            <v-btn
                icon
                @click.stop="showReplyDialog(reply)"
            >
              <v-icon>
                mdi-reply
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>


      <v-row
          v-intersect="onscroll"
          v-if="url"
      >
        <v-col
            v-for="i in 4"
            cols="12"
        >
          <v-skeleton-loader
              type="list-item-avatar-three-line, card-heading, actions"
              elevation="2"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-list>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">回复给 {{ replyingUserName }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
              >
                <v-form
                    ref="form"
                    v-model="replyValid"
                >
                  <v-textarea
                      solo
                      v-model="replyContent"
                      maxlength="200"
                      auto-grow
                      counter
                      rows="1"
                      :rules="replyRules"
                  ></v-textarea>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="cancelReplyDialog"
          >
            取消
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="replyToReply"
              :loading="loading"
          >
            回复
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { Message} from "element-ui"

export default {
  name: "ReceivedRepliesList",
  data: () => ({
    replies: [
    ],
    dialog: false,
    replyingUserName: '',
    replyContent: '',
    replyingArticleId: 0,
    rootId: 0,
    parentId: 0,
    replyRules: [
      v => !!v || '请输入回复',
    ],
    replyValid: false,
    loading: false,
  }),
  methods: {
    async getReplies(url) {
      const repliesResult = await this.$http.get(url)
      this.url = repliesResult.data.links.next
      // console.log(repliesResult.data.data)
      return repliesResult.data.data
    },
    async onscroll(entries, observer) {
      if(entries[0].isIntersecting) {
        if (this.url !== null) {
          const replies = await this.getReplies(this.url)
          this.replies.push(...replies)
        }
      }
    },
    showReplyDialog(reply) {
      this.dialog = true
      this.replyingUserName = reply.user.name
      this.replyingArticleId = reply.article.id
      this.rootId = reply.root_id || reply.id
      this.parentId = reply.id //新回复的回复对象
    },
    cancelReplyDialog() {
      this.replyingUserName = ''
      this.replyContent = ''
      this.replyingArticleId = 0
      this.rootId = 0
      this.dialog = false
      this.$refs.form.resetValidation()
    },
    async replyToReply() {
      if (this.$refs.form.validate()) {
        this.replyLoading = true

        try {
          let reply = await this.$http.post(`articles/${this.replyingArticleId}/replies`, {
            content : this.replyContent,
            root_id: this.rootId,
            parent_id: this.parentId,
          })
          // console.log(reply.data)
          Message({
            message: '回复成功',
            center: true,
            type: 'success',
          })
          this.cancelReplyDialog()
        } catch (e) {
          console.log(e)
        }

        this.replyLoading = false
      }
    },
  },
  created() {
    this.url = 'users/' + this.$route.params.userId + '/received-replies'
  },
}
</script>

<style scoped>
.article-title{
  white-space: initial !important;
}
</style>