<template>
  <v-container>
    <v-row>
      <v-col
          cols="12"
          md="4"
          sm="6"
          v-for="(article, index) in articles"
          :key="`article${index}`"
      >
        <v-hover
            v-slot="{ hover }"
        >
          <v-card
              :elevation="hover ? 23 : 3"
          >
            <v-list-item
                class="grow"
                dense
            >
              <v-list-item-avatar
                  size="30"
                  style="cursor: pointer;"
                  @click="$router.push({ name: 'User', params: { userId: article.user.id } })"
              >
                <v-img
                    :alt="article.user.name + ' avatar'"
                    :src="article.user.avatar"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content
              >
                <v-list-item-title
                    style="cursor: pointer;"
                    @click="$router.push({ name: 'User', params: { userId: article.user.id } })"
                >
                  {{ article.user.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ article.created_at }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-spacer></v-spacer>

              <UserVotable
                  v-bind:votable.sync="articles[index].user"
                  :likeCountIsShow="true"
              >
              </UserVotable>
            </v-list-item>

            <v-img
                :src="article.hero"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="300"
                @click="$router.push({ name: 'ArticleContent', params: { articleId: article.id } })"
                style="cursor: pointer"
                :lazy-src="require('@/assets/defaultImage.svg')"
            >
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-card-title
                class="title text-subtitle-1"
                v-text="article.title"
                @click="$router.push(`articles/${article.id}`)"
            ></v-card-title>
            <v-card-actions
            >
              <v-chip
                  v-if="!categoryId"
                  @click="$router.push({name: article.category.slug})"
              >
                {{article.category.name}}
              </v-chip>

              <v-spacer></v-spacer>

              <v-btn
                  icon
                  @click="$router.push({ path: `/articles/${article.id}#reply` })"
              >
                <v-icon small>mdi-message-reply-text</v-icon>
              </v-btn>
              <span class="mr-2">{{ article.reply_count }}</span>

              <ArticleVotable
                  :votable.sync="articles[index]"
              ></ArticleVotable>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row
        v-intersect="onscroll"
        v-if="url"
    >
      <v-col
          v-for="i in 12"
          cols="12"
          md="4"
          sm="6"
      >
        <v-skeleton-loader
            type="list-item-avatar-two-line, card, actions"
            elevation="2"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex"
import ArticleVotable from "@/components/ArticleVotable"
import UserVotable from "@/components/UserVotable"

export default {
  name: "Article",
  data: () => ({
    articles: [
    ],
    url: 'articles',
    categoryId: 0,
  }),
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
  },
  components: {
    ArticleVotable,
    UserVotable,
  },
  methods: {
    async getArticles(url) {
      let params = {
        include: `user.votingLikeUsers,user.votingDislikeUsers,votingLikeUsers,votingDislikeUsers${this.categoryId !== 0 ? '' : ',category'}`,
      }
      if (this.categoryId !==0 ) {
        params['filter[category_id]']= this.categoryId
      }
      const articlesResult = await this.$http.get(url, { params })
      this.url = articlesResult.data.links.next
      this.articles.push(...articlesResult.data.data)
      return articlesResult.data.data
    },
    async onscroll(entries, observer) {
      if(entries[0].isIntersecting) {
        if (this.url !== null) {
          await this.getArticles(this.url)
        }
      }
    },
    findCategoryId(slug) {
      if (slug === 'Home') {
        return 0
      }
      let categoryId
      this.$store.state.categories.some((category) => {
        if (category.slug === slug) {
          categoryId = category.id
          return true
        }
      })
      return categoryId
    },
  },
  created() {
    this.categoryId =  this.findCategoryId(this.$route.name)
  }
}
</script>

<style scoped>
 .title {
   overflow: hidden;
   -webkit-line-clamp: 2;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   height: 4.5rem;
   text-decoration: none !important;
   cursor: pointer;
 }
</style>