<template>
  <v-app>
    <TheSimpleHeader/>
    <v-main>
      <v-container
          fluid
          class="fill-height"
      >
        <Verify
            @success="success"
            mode="pop"
            :captchaType="captchaType"
            :imgSize="{ width: '330px', height: '155px' }"
            ref="verify"
        ></Verify>
        <v-card
            :flat="!$vuetify.breakpoint.smAndUp"
            class="mx-auto px-sm-13 py-sm-5 flex-grow-1"
            max-width="474"
            :class="{ 'transparent-card':  !$vuetify.breakpoint.smAndUp }"
        >
          <v-card-title
              class="mb-7"
          >
            创建账号
          </v-card-title>
          <v-card-text>
            <v-form
                v-model="valid"
            >
              <v-text-field
                  outlined
                  label="电子邮箱"
                  :value="email"
                  :rules="rules.email"
                  :error-messages="asyncErrorMessage.email"
                  @focus="asyncErrorMessage.email = ''"
                  readonly
              >
              </v-text-field>
              <v-text-field
                  outlined
                  label="邮箱验证码"
                  v-model="form.email_code"
                  :rules="rules.email_code"
                  :error-messages="asyncErrorMessage.email_code"
                  @focus="asyncErrorMessage.email_code = ''"
              >
              </v-text-field>
              <v-text-field
                  outlined
                  label="用户名"
                  v-model="form.name"
                  :rules="rules.name"
                  :error-messages="asyncErrorMessage.name"
                  @focus="asyncErrorMessage.name = ''"
              >
              </v-text-field>

              <v-text-field
                  outlined
                  label="密码"
                  v-model="form.password"
                  :rules="rules.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  :error-messages="asyncErrorMessage.password"
                  @focus="asyncErrorMessage.password = ''"
              >
              </v-text-field>
              <v-btn
                  block
                  x-large
                  color="#E50914"
                  class="white--text mt-3"
                  depressed
                  :loading="loading"
                  @click="validate()"
              >
                下一步
              </v-btn>
            </v-form>
          </v-card-text>

        </v-card>
      </v-container>
    </v-main>

  </v-app>

</template>

<script>
import { mapActions, mapState } from 'vuex'
import { Message } from 'element-ui'
import TheSimpleHeader from "@/components/TheSimpleHeader"
import Verify from "@/components/verifition/Verify"

export default {
  name: "Signup",
  components: {
    TheSimpleHeader,
    Verify,
  },
  data() {
    return {
      captchaType: ['clickWord', 'blockPuzzle'][Math.floor(Math.random()*2)],
      valid: false,
      loading: false,
      showPassword: false,
      form: {
        email_code: '',
        name: '',
        password: '',
      },
      rules: {
        email: [
          v => !!v || '请输入电子邮箱',
        ],
        email_code: [
          v => !!v || '请输入邮箱验证码',
        ],
        name: [
          v => !!v || '请输入用户名',
        ],
        password: [
          v => !!v || '请输入密码',
        ],
      },
      asyncErrorMessage: {
        email: '',
        email_code: '',
        name: '',
        password: '',
      },
    }
  },
  created() {
    //邮箱验证码未发送，跳转到首页去发送验证码
    if (!this.$store.state.user.signUpEmail) {
      this.$router.push({ name: 'Home' })
    }
    this.email = this.$store.state.user.signUpEmail
  },
  methods: {
    ...mapActions([
        'signUp',
    ]),
    success(captchaVerification){
      this.validate(captchaVerification)
    },
    useVerify(){
      this.$refs.verify.show()
    },
    async validate(captchaVerification) {
      if (this.valid) {
        this.loading = true
        let data = this.form
        if (captchaVerification) {
          data = Object.assign({}, data, captchaVerification)
        }
        try {
          // codeKey 在 EmailForm 中存入 store 中
          const res = await this.signUp({...data, 'code_key': this.$store.state.user.codeKey })
          //注册成功后清除 state 中的email 和 codeKey
          this.$store.commit('removeSignUpEmailAndCodeKey')
          Message({
            message: '注册成功！请登录。',
            type: 'success',
            center: true,
            onClose: message => {
              this.$router.push({ name: 'Signin' })
            }
          })
        } catch (e) {
          if (e.response && e.response.status) {
            if (e.response.status === 422) {
              const errors = e.response.data.errors
              Object.assign(this.asyncErrorMessage, errors)
            } else if (e.response.status === 403) {
              this.asyncErrorMessage.email_code = e.response.data.message
            } else if (e.response.status === 429) {
              this.useVerify()
            }
          }
        }
        this.loading = false
      }
    },
  }
}
</script>

<style scoped>
  .transparent-card {
    background-color: transparent !important;
  }
</style>