<template>
  <v-card
      class="mt-4"
      :flat="$vuetify.breakpoint.smAndDown"
      id="reply"
  >
    <v-card-title>
      评论 &nbsp;{{ count }}
    </v-card-title>


    <v-list
    >
      <v-list-item
          class="align-start justify-start"
      >
        <v-list-item-avatar
            class="align-self-start"
            :color="user ? '' : 'green'"
        >
          <v-img v-if="user" :src="user && user.avatar"></v-img>
          <v-icon
              v-else>
            mdi-account
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            <v-form
                v-model="valid"
                ref="replyArticleForm"
            >
              <v-textarea
                  solo
                  v-model="articleReplyContent"
                  maxlength="200"
                  auto-grow
                  counter
                  rows="1"
                  placeholder="畅所欲言"
                  :rules="replyRules"
              ></v-textarea>
              <v-btn
                  class="float-right"
                  @click="reply"
                  :loading="loading"
              >
                回复
              </v-btn>
            </v-form>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>


      <template
          v-for="(reply, index) of replies"
      >
        <v-divider
            inset
            v-if="index !== 0"
        ></v-divider>

        <Reply
            :reply="reply"
            :rootReply="replies[index]"
            v-on:showReplyDialog="onShowReplyDialog"
            v-bind:votable.sync="replies[index]"
        >

        </Reply>
      </template>

      <v-skeleton-loader
          v-intersect="onscroll"
          v-if="repliesUrl"
          cols="12"
          type="list-item-avatar-three-line, actions"
          elevation="2"
      ></v-skeleton-loader>
    </v-list>

    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">回复给 {{ replyingUserName }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
              >
                <v-form
                    ref="form"
                    v-model="replyValid"
                >
                  <v-textarea
                      solo
                      v-model="replyContent"
                      maxlength="200"
                      auto-grow
                      counter
                      rows="1"
                      :rules="replyRules"
                  ></v-textarea>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="cancelReplyDialog"
          >
            取消
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="replyToReply"
              :loading="loading"
          >
            回复
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Reply from "@/components/Reply";
import {Message} from "element-ui";

export default {
  name: "Replies",
  components: {
    Reply,
  },
  props: {
    count: {
      type: Number,
      default: 0,
    },
    articleId: {
      type: Number,
      default: 0,
    },
    articleUser: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
  },
  data() {
    return {
      replies: [],
      valid: false,
      articleReplyContent: '',
      replyRules: [
        v => !!v || '请输入回复',
      ],
      loading: false,
      repliesUrl: '',
      dialog: false,
      replyingUserName: '',
      replyContent: '',
      rootId: 0,
      parentId: 0,
      replyValid: false,
      rootReply: {},
    }
  },
  methods: {
    async getReplies(url) {
      const replies = await this.$http.get(url, { params: { include: 'user,replies,replies.user,replies.parent.user,votingLikeUsers,votingDislikeUsers,replies.votingLikeUsers,replies.votingDislikeUsers'} })
      this.repliesUrl = replies.data.links.next
      // console.log(replies.data.data)
      return replies.data.data
    },
    async onscroll(entries, observer) {
      if(entries[0].isIntersecting) {
        if (this.repliesUrl !== null) {
          const replies = await this.getReplies(this.repliesUrl)
          this.replies.push(...replies)
        }
      }
    },
    // 回复文章
    async reply() {
      if (this.valid) {
        this.loading = true

        const data = { content: this.articleReplyContent}
        try {
          const reply = await this.$http.post(`articles/${this.articleId}/replies?include=user,replies,replies.user,votingLikeUsers,votingDislikeUsers,replies.votingLikeUsers,replies.votingDislikeUsers`, data)
          // console.log(reply.data)
          this.replies.push(reply.data)
          this.$refs.replyArticleForm.reset()
          Message({
            message: '回复成功',
            center: true,
            type: 'success',
          })
        } catch (e) {
          console.log(e)
        }

        this.loading = false
      }
    },
    onShowReplyDialog(rootReply, reply) {
      this.dialog = true
      this.replyingUserName = reply.user.name
      this.rootId = reply.root_id || reply.id
      this.parentId = reply.id //新回复的回复对象
      this.rootReply = rootReply
    },
    cancelReplyDialog() {
      this.dialog = false
      this.replyingUserName = ''
      this.replyContent = ''
      this.rootId = 0
      this.rootReply = {}
      this.$refs.form.resetValidation()
    },
    async replyToReply() {
      if (this.$refs.form.validate()) {
        this.replyLoading = true

        try {
          let reply = await this.$http.post(`articles/${this.articleId}/replies`, {
            content : this.replyContent,
            root_id: this.rootId,
            parent_id: this.parentId,
          })
          this.rootReply.replies.push(reply.data)
          Message({
            message: '回复成功',
            center: true,
            type: 'success',
          })
          this.cancelReplyDialog()
        } catch (e) {
          console.log(e)
        }

        this.replyLoading = false
      }
    },
  },
  created() {
    this.repliesUrl = `articles/${this.articleId}/replies`
  }
}
</script>

<style scoped>

</style>