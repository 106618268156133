<template>
  <v-app>
    <v-main>
      <v-container
          class="fill-height text-center"
      >
        <v-row
            align="center"
        >
          <v-col
              cols="12"
              md="9"
              order-md="last"
          >
            <v-img
                :src="require('@/assets/NotFound.svg')"
            ></v-img>
          </v-col>
          <v-col
              order-md="first"
              cols="12"
              md="3"
          >
            <h1 class="text-h4">
              对不起，您查找的页面不存在！
            </h1>
            <v-btn
                :to="{ name: 'Home' }"
                color="primary"
                class="mt-4"
            >
              回到主页
            </v-btn>

          </v-col>

        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>
  
</style>