<template>
  <v-app>
    <v-app-bar
        app
        class="transparent"
        height="95"
        flat
        absolute
    >
      <router-link
          :to="{ name:'Home' }"
          class="d-inline-block px-4"
      >
        <v-img
            :src="require('@/assets/logo.svg')"
            max-width="34"
            :aspect-ratio="1"
            transition="scale-transition"
        >
        </v-img>
      </router-link>
    </v-app-bar>
    <v-img
        :src="bgiUrl"
        style="background-color: black; height: calc(100vh - 95px);"
    >
      <v-container
          class="fill-height"
      >
        <Verify
            @success="success"
            mode="pop"
            :captchaType="captchaType"
            :imgSize="{ width: '330px', height: '155px' }"
            ref="verify"
        ></Verify>
        <v-row
            justify="center"
            no-gutters
        >
          <v-col
          >
            <v-card
                :flat="!$vuetify.breakpoint.smAndUp"
                max-width="474"
                class="mx-auto px-sm-13 py-sm-15 sign-card"
            >
              <v-card-title>
                <h2 class="mb-7 white--text">登录</h2>
              </v-card-title>
              <v-card-text>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                  <v-text-field
                      v-model="form.username"
                      :rules="rules.username"
                      label="电子邮箱或用户名"
                      outlined
                      background-color="rgb(51, 51, 51)"
                      color="rgb(140, 140, 140)"
                      class="custom-color"
                      @focus="asyncErrorMessage.username = ''"
                      :error-messages="asyncErrorMessage.username"
                  ></v-text-field>
                  <v-text-field
                      v-model="form.password"
                      :rules="rules.password"
                      label="密码"
                      outlined
                      background-color="rgb(51, 51, 51)"
                      color="rgb(140, 140, 140)"
                      class="custom-color"
                      @focus="asyncErrorMessage.username = ''"
                      :error-messages="asyncErrorMessage.password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                  ></v-text-field>
                  <v-btn
                      block
                      x-large
                      color="#E50914"
                      class="white--text my-3"
                      depressed
                      @click="validate()"
                      :loading="loading"
                  >
                    登录
                  </v-btn>

                  <div
                      class="d-flex justify-space-between align-center"
                  >
                    <v-checkbox
                        v-model="form.remember"
                        label="记住我"
                        class="white-text-checkbox"
                    ></v-checkbox>
                    <router-link
                        :to="{ name: 'ResetPassword' }"
                        class="text-decoration-none forget-password white--text"
                    >
                      忘记密码？
                    </router-link>
                  </div>
                </v-form>

                <p
                    class="mt-6 white--text"
                >
                  第一次使用？
                  <router-link
                      :to="{ name: 'Home' }"
                      class="text-decoration-none blue--text"
                  >
                    立即注册
                  </router-link>
                </p>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
      </v-container>

    </v-img>
  </v-app>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import Verify from "@/components/verifition/Verify"

export default {
  username: "Signin",
  data() {
    return {
      captchaType: ['clickWord', 'blockPuzzle'][Math.floor(Math.random()*2)],
      valid: true,
      loading: false,
      showPassword: false,
      form: {
        username: '',
        password: '',
        remember: true,
      },
      rules: {
        username: [
          v => !!v || '请输入电子邮箱或用户名',
        ],
        password: [
          v => !!v || '请输入密码',
        ],
      },
      asyncErrorMessage: {
        username: '',
        password: '',
      },
    }
  },
  components: {
    Verify,
  },
  computed: {
    bgiUrl() {
      return this.$vuetify.breakpoint.smAndUp ?
          require('@/assets/sign_in_background.jpg')
          : ''
    }
  },
  methods: {
    ...mapActions([
        "signIn",
    ]),
    success(captchaVerification){
      this.validate(captchaVerification)
    },
    useVerify(){
      this.$refs.verify.show()
    },
    async validate(captchaVerification) {
      if (this.$refs.form.validate()) {
        this.loading = true
        let data = this.form
        if (captchaVerification) {
          data = Object.assign({}, data, captchaVerification)
        }
        try {
          await this.signIn(data)
          if (this.$route.params && this.$route.params.redirect) {
            this.$router.push(this.$route.params.redirect)
          } else {
            this.$router.push({ name: 'Home' })
          }
        } catch (e) {
          if (e.response) {
            const res = e.response
            if (res.status === 400) {
              if(res.data.hint && res.data.hint.indexOf('username') !== -1) {
                this.asyncErrorMessage.username = '请输入电子邮箱或用户名'
              } else if(res.data.hint && res.data.hint.indexOf('password') !== -1) {
                this.asyncErrorMessage.password = '请输入密码'
              } else {
                this.asyncErrorMessage.username = '用户名或密码错误'
              }
            } else if(res.status === 429) {
              this.useVerify()
            } else {
              console.log(res)
            }
          } else {
            console.log(e)
          }
        }
        this.loading = false
      }
    },
  }
}
</script>

<style>
  .sign-card {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  .custom-color .v-label {
    color: rgb(238, 236, 236) !important;
  }
  .custom-color input {
    color: white !important;
  }
  .forget-password {
    font-size: 16px;
  }
  .white-text-checkbox .v-label {
    color: white !important;
  }
</style>